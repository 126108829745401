import { gql } from '@apollo/client';

export const ME_QUERY = gql`
  {
    me {
      id
      displayName
      email
      roles
      org {
        id
        name
      }
      userSettings {
        theme
      }
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUserDetails($updatedUserDetails: UpdateUserWithToken!) {
    updateUserWithToken(input: $updatedUserDetails) {
      user {
        id
        displayName
      }
      error {
        message
      }
      clientMutationId
      passwordChanged
    }
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation updateUserDetails($updatedUserDetails: UpdateUserWithToken!) {
    updateUserWithToken(input: $updatedUserDetails) {
      user {
        id
        userSettings {
          theme
        }
      }
      error {
        message
      }
      clientMutationId
      passwordChanged
    }
  }
`;
