import { gql } from '@apollo/client';

const GET_VERSIONS = gql`
  {
    serverStatus {
      name
      version
    }
  }
`;

export default GET_VERSIONS;
