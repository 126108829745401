import { makeStyles } from '@material-ui/core/styles';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    '& [class*="MuiInputBase-input"]': {
      lineHeight: '1.5rem',
      padding: '0.75rem',
      height: 'auto',
      color: theme.palette.typography.primary || ColorPalette.black00,
      background: theme.palette.inputs.background,
    },
    '& [class*="MuiOutlinedInput-adornedStart"]': {
      paddingLeft: '0.75rem',
      background:
        (theme.palette.inputs && theme.palette.inputs.background) ||
        ColorPalette.white01,
      '& > [class*="MuiInputBase-input"]': {
        background: 'none',
      },
      '& [class*="Muitext-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
        fontWeight: 500,
      },
      '& [class*="MuiSvgIcon-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
      },
    },
    '& [class*="MuiOutlinedInput-adornedEnd"]': {
      paddingRight: '0.75rem',
      background:
        (theme.palette.inputs && theme.palette.inputs.background) ||
        ColorPalette.white01,
      '& > [class*="MuiInputBase-input"]': {
        background: 'none',
      },
      '& [class*="Muitext-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
        fontWeight: 500,
      },
      '& [class*="MuiSvgIcon-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
      },
    },
    '& [class*="MuiInputBase-adornedStart"] > [class*="MuiOutlinedInput-input"]':
      {
        paddingLeft: 0,
      },
    '& [class*="MuiInputBase-adornedEnd"] > [class*="MuiOutlinedInput-input"]':
      {
        paddingRight: 0,
      },
    '& [class*="MuiInputBase-multiline"]': {
      padding: '0.75rem',
      background:
        (theme.palette.inputs && theme.palette.inputs.background) ||
        ColorPalette.white01,
    },
    '& [class*="MuiInputBase-inputMultiline"]': {
      padding: 0,
      lineHeight: '1.5rem',
      minHeight: '3rem',
      background: 'none',
    },
    '& [class*="MuiFormHelperText-root"]': {
      marginLeft: 0,
      fontWeight: 400,
      color:
        (theme.palette.typography && theme.palette.typography.primary) ||
        ColorPalette.black00,
    },
    '& [class*="MuiFormHelperText-root"][class*="Mui-error"]': {
      color: ColorPalette.red00,
    },
    '& [class*="MuiFormLabel-root"][class*="Mui-disabled"]': {
      color: theme.palette.background.disabled,
    },
    '& [class*="MuiFormHelperText-root"][class*="Mui-disabled"]': {
      opacity: '30%',
    },
    '& [class*="MuiInput-underline"] > [class*="MuiInputBase-input"]': {
      padding: '6px 0 7px',
    },
  },
  cssOutlinedInput: {
    '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline':
      {
        borderColor:
          (theme.palette.inputs && theme.palette.inputs.border) ||
          ColorPalette.black00,
      },
    '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
      borderColor:
        (theme.palette.inputs && theme.palette.inputs.borderHover) ||
        ColorPalette.black00,
      background: theme.palette.inputs && theme.palette.inputs.backgroundHover,
    },
  },
  formhelperspacing: {
    marginTop: '4px',
    marginLeft: 0,
  },
  notchedOutline: {
    borderWidth: '1px !important',
  },
  cssFocused: {},
  error: {
    borderColor: theme.fdProColors.alert.errorDark,
  },
  disabled: {
    color: theme.palette.background.disabled,
  },
  labelRoot: {
    lineHeight: '1.5rem',
    fontWeight: 600,
    color:
      (theme.palette.typography && theme.palette.typography.primary) ||
      ColorPalette.black00,
    overflowWrap: 'break-word',
  },
  optionalLabel: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color:
      (theme.palette.typography && theme.palette.typography.secondary) ||
      ColorPalette.black00,
  },
}));

export default useStyles;
