import ctfIcon from '../../resources/icons/challenges-icon.svg';
import rangeIcon from '../../resources/icons/range-icon.svg';
import labIcon from '../../resources/icons/labs-icon.svg';
import { ColorPalette } from '../shared/colors';
import typography from '../typography/typography';
import { fdProColors } from './fd-pro-colors';

const c = {
  background: 'rgba(0, 14, 41, 1)',
  paper: 'rgba(7, 3, 15, 1)',
  divider: 'rgba(248, 193, 45, 1)',
  primary: '#1976D2',
  primary_main: 'rgba(99, 164, 255, 1)',
  primary_dark: 'rgba(28, 118, 203, 1)',
  primary_light: 'rgba(155, 213, 255, 1)',

  secondary: 'rgba(248, 193, 45, 1)',
  secondary_dark: 'rgba(248, 193, 45, 1)',
  secondary_light: 'rgba(253, 255, 88, 1)',

  text_primary: '#fff',
  icon_secondary: 'rgba(255, 255, 255, 0.54)',
  icon_placeholder: 'rgba(255, 255, 255, 0.38)',
  text_secondary: 'rgba(255, 255, 255, 0.7)',
  text_placeholder: 'rgba(255, 255, 255, 0.5)',
  text_disabled: 'rgba(255, 255, 255, 0.24)',
  link: '#4D9BE5',

  hover: 'rgba(23, 56, 106, 1)',
  selected: 'rgba(23, 56, 106, 1)',
  input_background: 'rgba(255,255,255,0.05)',
  input_border: 'rgba(248, 193, 45, 1)',
  input_background_hover: 'rgba(23, 56, 106, 1)',
  input_border_hover: '#E3E6EC',
  select_border: 'rgba(248, 193, 45, 1)',

  thumb: '#424242',
};

const theme = {};

theme.common = {
  black: '#000',
  white: '#fff',
};
theme.fdProColors = fdProColors;
theme.resources = { ctfIcon, rangeIcon, labIcon };

theme.palette = {
  type: 'dark',
  mode: 'black-gold',
  contrastThreshold: 3,
  background: {
    default: c.background,
    paper: c.paper,
    border: c.secondary,
    divider: c.divider,
    disabled: ColorPalette.grey05,
    thumb: c.thumb,
    menu: c.background,
    sidebar: c.paper,
  },
  primary: {
    main: c.primary,
    dark: c.primary_dark,
    light: c.primary_light,
  },
  secondary: {
    main: c.secondary,
    dark: c.secondary_dark,
    light: c.secondary_light,
  },
  iconography: {
    primary: c.text_primary,
    secondary: c.icon_secondary,
    placeholder: c.icon_placeholder,
    disabled: c.icon_placeholder,
  },
  text: {
    primary: c.text_primary,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: c.text_placeholder,
    link: c.primary,
    background: c.input_background,
  },
  typography: {
    primary: c.text_primary,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: ColorPalette.black00,
    link: c.link,
  },
  inputs: {
    background: c.input_background,
    backgroundHover: c.input_background_hover,
    border: c.input_border,
    borderHover: c.input_border_hover,
    selectBorder: c.select_border,
  },
  table: {
    border: 'rgba(248, 193, 45, 1)',
    hover: 'rgba(255, 255, 255, 0.08)',
    selected: 'rgba(248, 193, 45, 0.25)',
  },
  toggleButton: {
    unSelectedColor: ColorPalette.white01,
  },
  action: {
    hover: c.hover,
    selected: c.selected,
  },
  disabled: {
    background: '#0000004D',
    text: '#ffffff',
  },
};

theme.typography = typography;
theme.typography = {
  fontFamily: 'Montserrat',
  h1: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '2.5rem',
    letterSpacing: 0,
  },
  h2: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '2rem',
    letterSpacing: 0,
  },
  h3: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '1.5rem',
    letterSpacing: 0,
  },
  h4: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '1.25rem',
    letterSpacing: 0,
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 0,
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.8125rem',
    letterSpacing: 0,
  },
  button_sm: {
    fontFamily: 'Raitor',
    fontWeight: 600,
    fontSize: '0.75rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  button_med: {
    fontFamily: 'Raitor',
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  button_lg: {
    fontFamily: 'Raitor',
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  captiontext1: {
    fontWeight: 400,
    letterSpacing: 0,
    fontSize: '0.75rem',
  },
  captiontext2: {
    fontWeight: 400,
    letterSpacing: 0,
    fontSize: '0.625rem',
  },
};

export default theme;
