import React, { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import shortid from 'shortid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import FdCard from '../FdCard';
import ChatMessage from './ChatMessage';
import AddComment from './AddComment';
import { initialValues, validationSchema } from './validation-schema';
import { scrollToAnchor } from './utils';
import globalStore from '../../store';

const ChatStream = ({
  allMessages,
  headerHeight,
  userList,
  onAddMessage,
  onUpdateMessage,
  onDeleteMessage,
  onCancelDeleteMessage,
  onSetReaction,
  viewOnly,
  hideReactions,
  noRoles,
  currentRole,
  adminView,
  useReaction,
  teams,
  reactionsViewOnly,
  size,
  shortMode,
}) => {
  const listRef = useRef(null);
  const rowHeights = useRef({});
  const globalSnap = useSnapshot(globalStore);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, control } = reactHookFormMethods;

  const {
    fields: messages,
    append: addMessage,
    remove: removeMessage,
    update: updateMessage,
  } = useFieldArray({
    control,
    name: 'messages',
  });
  const lastIndex = messages.length - 1;

  useEffect(
    () =>
      reset({
        messages: allMessages,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allMessages?.length],
  );

  const getRowHeight = (index) => {
    return rowHeights.current[index] || 140;
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(messages.length - 1, 'end');
      scrollToAnchor('last-msg-anchor');
    }
  }, [messages.length]);

  const setRowHeight = (index, _size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = {
      ...rowHeights.current,
      [index]: _size + (size === 'small' ? 35 : 24),
    };
  };

  return (
    <FdCard variant="outlined">
      <FormProvider {...reactHookFormMethods}>
        <Box
          className="flex flex-col"
          style={{ height: `calc(100vh - ${headerHeight}px)` }}
        >
          <VariableSizeList
            ref={listRef}
            height={window.innerHeight}
            width="100%"
            itemCount={messages.length}
            itemSize={(index) => getRowHeight(index)}
            estimatedItemSize={300}
          >
            {({ index, style }) => (
              <div style={style}>
                <ChatMessage
                  message={messages[index]}
                  index={index}
                  setRowHeight={setRowHeight}
                  lastIndex={lastIndex}
                  onDelete={() => {
                    const { messageId, keyId } = messages[index] || {};
                    removeMessage(index);
                    onDeleteMessage(messageId, keyId);
                  }}
                  onDeleteCancel={() => {
                    onCancelDeleteMessage();
                  }}
                  userList={userList}
                  updateMessage={updateMessage}
                  onUpdateMessage={onUpdateMessage}
                  onSetReaction={onSetReaction}
                  viewOnly={viewOnly}
                  noRoles={noRoles}
                  hideReactions={hideReactions}
                  adminView={adminView}
                  useReaction={useReaction}
                  reactionsViewOnly={reactionsViewOnly}
                  size={size}
                  shortMode={shortMode}
                />
              </div>
            )}
          </VariableSizeList>
          {!viewOnly && (
            <AddComment
              onAddComment={(message, imagePreview, imageFile) => {
                const team = teams?.find((t) =>
                  t?.team?.members.items.some(
                    (member) => member?.userId === globalSnap?.userId,
                  ),
                )?.team;
                const keyId = shortid.generate(); // temporary id to track new messages
                addMessage({
                  comment: message,
                  userName: globalSnap?.userAlias,
                  dateTime: new Date(),
                  image: imagePreview,
                  role: currentRole,
                  userId: globalSnap?.userId,
                  team,
                  keyId,
                });
                onAddMessage(message, imageFile, keyId);
              }}
              userList={userList}
              shortMode={shortMode}
            />
          )}
        </Box>
      </FormProvider>
    </FdCard>
  );
};

ChatStream.defaultProps = {
  headerHeight: 260,
  userList: [],
  viewOnly: false,
  hideReactions: false,
  noRoles: false,
  currentRole: '',
  adminView: false,
  useReaction: () => null,
  teams: [],
  reactionsViewOnly: false,
  size: 'big',
  shortMode: false,
};

ChatStream.propTypes = {
  allMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headerHeight: PropTypes.number,
  userList: PropTypes.arrayOf(PropTypes.string),
  onAddMessage: PropTypes.func.isRequired,
  onUpdateMessage: PropTypes.func.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  onCancelDeleteMessage: PropTypes.func.isRequired,
  onSetReaction: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  hideReactions: PropTypes.bool,
  noRoles: PropTypes.bool,
  currentRole: PropTypes.string,
  adminView: PropTypes.string,
  useReaction: PropTypes.func,
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  reactionsViewOnly: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small']),
  shortMode: PropTypes.bool,
};

export default ChatStream;
