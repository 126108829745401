/* FdBreadcrumbs */
import React from 'react';
import PropTypes from 'prop-types';
import { navigateToUrl } from 'single-spa';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useStyle from './style';

function FdBreadcrumbs({
  linkChain,
  currentPage,
  linkComponent = RouterLink,
  renderAsButton,
}) {
  const classes = useStyle();

  return (
    <>
      <Breadcrumbs
        separator="/"
        aria-label="breadcrumb"
        classes={{ separator: classes.separator }}
      >
        {linkChain.map(({ name, url }) =>
          url ? (
            <Link
              id={`breadcrumb-${url}`}
              to={url}
              key={url}
              onClick={() => renderAsButton && navigateToUrl(url)}
              component={renderAsButton ? 'button' : linkComponent}
              className={renderAsButton ? classes.linkButton : classes.linkText}
            >
              {name}
            </Link>
          ) : (
            <Typography
              id="page-breadcrumb-without-link"
              className={classes.selectedText}
            >
              {name}
            </Typography>
          ),
        )}
        ;
        <Typography
          id="current-page-breadcrumb"
          className={classes.selectedText}
        >
          {currentPage}
        </Typography>
      </Breadcrumbs>
      <Box p={1} />
    </>
  );
}

FdBreadcrumbs.propTypes = {
  /** Array of Objects with relative url (eg '/here') and display name */
  linkChain: PropTypes.arrayOf(
    PropTypes.shape({ url: PropTypes.string, name: PropTypes.string }),
  ).isRequired,
  /** Name of page that is displayed at the end of the Breadcrumbs */
  currentPage: PropTypes.string.isRequired,
  linkComponent: PropTypes.shape({}),
  renderAsButton: PropTypes.bool,
};

FdBreadcrumbs.defaultProps = {
  linkComponent: null,
  renderAsButton: false,
};

export default FdBreadcrumbs;
