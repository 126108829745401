const Permissions = {
  CHALLENGE_CREATE: 'ctfchallenge:create',
  CHALLENGE_REVIEW: 'ctfchallenge:review',
  COMPETITION_RUN: 'ctfcompetition:run',
  COMPETITION_SET: 'ctftemplate:create',
  FIFTHDOMAIN: 'fifthdomain',
  ADMIN: 'admin',
};

export default Permissions;
