import owasp from 'owasp-password-strength-test';

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 10,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4,
});

export const passwordConfirmHelp = (password, passwordConfirm) => {
  if (password !== passwordConfirm) {
    return 'Passwords must match';
  }
  return null;
};

export const passwordConfirmValidation = (password, passwordConfirm) => {
  if (password === '') {
    return true;
  }
  return passwordConfirmHelp(password, passwordConfirm) === null;
};

export const passwordValidation = (password, passwordConfirm) => {
  const { length } = password;

  if (password !== passwordConfirm) {
    return 'error';
  }

  if (length === 0) {
    return null;
  }

  if (length > 128) {
    return 'error';
  }

  if (owasp.test(password).errors.length !== 0) {
    return 'error';
  }

  return 'success';
};

export const passwordHelp = (password, passwordConfirm) => {
  const { length } = password;

  if (length === 0) {
    return null;
  }

  const owaspTest = owasp.test(password);
  if (owaspTest.errors.length) {
    return owaspTest.errors[0];
  }

  if (password !== passwordConfirm) {
    return 'Passwords must match';
  }
  return undefined;
};

export const passwordRuleMessages = (newPassword) => {
  // Min 10 characters, max 128 characters
  // (for passwords with 10-19 characters) Invalid entry: any entry that does not have an upper case letter, lower case letter, number AND symbol

  // related error messages
  // This field is required
  // The password must be at least 10 characters long
  // The password must be fewer than 128 characters
  // Passwords must contain at least one upper case letter, lower case letter, number and symbol

  const pwd = newPassword?.trim();

  switch (true) {
    case pwd.length < 10:
      return 'The password must be at least 10 characters long';
    case pwd.length > 128:
      return 'The password must be fewer than 128 characters';
    case !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(pwd):
      return 'Passwords must contain at least one upper case letter, lower case letter, number and symbol';

    default:
      return undefined;
  }
};

export default owasp;
