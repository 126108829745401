import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Tooltip, makeStyles } from '@material-ui/core';
import FdAvatar from '../FdAvatar';

const sizeMap = {
  xsmall: '24',
  small: '32',
  medium: '40',
  large: '48',
};

const fontSizeMap = {
  xsmall: '12',
  small: '12',
  medium: '20',
  large: '24',
};

const useStyles = makeStyles({
  avatar: ({ size, variant }) => ({
    border: 'none',
    width: `${sizeMap[size]}px`,
    height: `${sizeMap[size]}px`,
    fontSize: `${fontSizeMap[size]}px`,
    borderRadius: variant === 'square' && '4px',
    marginLeft: '-5px !important',
  }),
});

const FdAvatarGroup = ({ variant, avatars, size, ...rest }) => {
  const classes = useStyles({ size, variant });

  return (
    <AvatarGroup {...rest} classes={{ avatar: classes.avatar }}>
      {avatars?.length > 0 &&
        avatars.map((avatar) => {
          const Badge = avatar.badge || React.Fragment;
          const badgeProps = avatar.badge
            ? {
                variant: 'dot',
                overlap: 'circular',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }
            : {};
          const AvatarToolTip = avatar.tooltip ? Tooltip : React.Fragment;
          const avatarToolTipProps = avatar.tooltip
            ? { interactive: true, title: avatar.tooltip }
            : {};

          return (
            <AvatarToolTip {...avatarToolTipProps}>
              <Badge {...badgeProps}>
                <FdAvatar
                  key={shortid.generate()}
                  size={size}
                  variant={variant}
                  content={avatar.content}
                  color={avatar.color}
                />
              </Badge>
            </AvatarToolTip>
          );
        })}
    </AvatarGroup>
  );
};

FdAvatarGroup.propTypes = {
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['circle', 'circular', 'square']),
};

FdAvatarGroup.defaultProps = {
  size: 'medium',
  variant: 'circular',
};

export default FdAvatarGroup;
