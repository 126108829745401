import { useContext, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useLazyQuery, gql } from '@apollo/client';
import { listAffliationsByUserId } from '../../queries/customQueries';
import globalStore from '../../store';
import { AuthContext } from '../../contexts';
import useLocalStorage from '../useLocalStorage';
import { getUserType } from './utils';
import { setTheme } from '../../subjects/theme-subject';

const useInitializeAffiliatedStore = () => {
  const snap = useSnapshot(globalStore);
  const { user } = useContext(AuthContext);

  const [userPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar-user-mode',
  );

  // get affiliation status
  const [getAffiliation] = useLazyQuery(gql(listAffliationsByUserId), {
    fetchPolicy: 'network-only',
    skip: !user,
    onCompleted: (_data) => {
      const affiliationDetails =
        _data?.listAffliationsByUserId?.items?.filter(
          (a) => a?.status === 'ACTIVE',
        )[0] || {};
      if (affiliationDetails?.status === 'ACTIVE') {
        globalStore.orgId = affiliationDetails?.orgId;
        globalStore.orgAlias = affiliationDetails?.org?.alias || '';
        globalStore.orgName = affiliationDetails?.org?.name || '';
        globalStore.orgPricingTier = affiliationDetails?.org?.pricingTier || '';
        globalStore.orgMaxInvitees = affiliationDetails?.org?.maxInvitees || '';
        globalStore.isAffiliated = true;
        if (snap.hasManagerialPermissions) {
          globalStore.mode = 'Manage';
        }
      } else {
        globalStore.isAffiliated = false;
      }
      // set user type based on user preferences and permissions
      const userType = getUserType({
        userPreferencesSidebarMode:
          userPreferencesSidebar?.[user?.username]?.mode,
        hasManagerialPermission: snap.hasManagerialPermissions,
      });
      globalStore.userType = userType;

      const userTheme = affiliationDetails?.user?.theme?.toLowerCase();
      // for admins light theme is default
      let theme = userType === 'ADMIN' ? 'light' : userTheme || 'dark';
      // for participants dark theme is default but will be light if set in user profile
      if (userType === 'PARTICIPANT' && snap.theme === 'light') {
        theme = 'light';
      }
      const mainContent = document.querySelector('.main-content');
      mainContent.style.backgroundColor = theme === 'dark' ? '#000' : '#fff';
      setTheme(theme);
      globalStore.loading = false;
    },
    onError: (err) => {
      globalStore.loading = false;
      globalStore.error = err;
    },
  });

  useEffect(() => {
    if (snap.isAffiliated) {
      getAffiliation({
        variables: {
          userId: user?.username,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAffiliation, snap.isAffiliated, user?.username]);
};

export default useInitializeAffiliatedStore;
