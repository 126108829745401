import { gql } from '@apollo/client';

const GET_ORGANISATION = gql`
  query orgById($id: ID!) {
    orgById(id: $id) {
      users(first: 2000) {
        edges {
          node {
            id
            displayName
            email
            roles
          }
        }
      }
    }
  }
`;

export default GET_ORGANISATION;
