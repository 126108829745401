import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import FdSkeleton from '../FdSkeleton/FdSkeleton';

// Render with a delay, show spinner during delay. Use a trigger field to re-render.
const FdDelayed = ({
  delay,
  triggerField,
  hideProgress,
  showSkeleton,
  children,
  ...props
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(false);
    const timer = setTimeout(() => {
      setIsShown(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay, triggerField]);

  return isShown
    ? children
    : !hideProgress &&
        (showSkeleton ? (
          <Box mr={1}>
            <FdSkeleton loading {...props}>
              <></>
            </FdSkeleton>
          </Box>
        ) : (
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            m={2}
            {...props}
          >
            <CircularProgress />
          </Box>
        ));
};

FdDelayed.propTypes = {
  delay: PropTypes.number,
  // use trigger field to re-render
  triggerField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  hideProgress: PropTypes.bool,
  showSkeleton: PropTypes.bool,
};

FdDelayed.defaultProps = {
  delay: 500,
  triggerField: undefined,
  hideProgress: false,
  showSkeleton: false,
};

export default FdDelayed;
