import React, { useEffect, useRef, useState } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { CircularProgress, LinearProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FdTypography from '../FdTypography';

const sizeMap = {
  small: 32,
  medium: 48,
  large: 72,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.type === 'dark' ? '#131353' : '#E0E0E0',
  },
  top: {
    color: '#3333FF',
    animationDuration: '1000ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  linearProgress: {
    height: '8px',
    width: '294px',
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.type === 'dark' ? '#131353' : '#E0E0E0',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#3333FF',
  },
}));

function FdProgress({ type, size, caption, ...otherProps }) {
  const classes = useStyles();
  const progressRef = useRef(null);
  const [aniValue, setAniValue] = useState(0);

  useEffect(() => {
    anime({
      targets: { value: 0 },
      value: otherProps.value,
      easing: 'easeInOutQuad',
      duration: 2000, // Adjust the duration as needed
      update: (anim) => {
        setAniValue(anim.animations[0].currentValue);
      },
    });
  }, [otherProps.value]);

  if (type === 'linear') {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.linearProgress,
            bar: classes.bar,
            colorPrimary: classes.colorPrimary,
          }}
          ref={progressRef}
          {...otherProps}
          value={aniValue}
        />
        {caption && (
          <Box pt={1}>
            <FdTypography variant="captiontext2">{caption}</FdTypography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <CircularProgress
        variant="determinate"
        size={sizeMap[size]}
        className={classes.bottom}
        value={100}
        thickness={4}
        {...otherProps}
      />
      <CircularProgress
        variant="indeterminate"
        size={sizeMap[size]}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        thickness={4}
        {...otherProps}
      />
    </Box>
  );
}

FdProgress.defaultProps = {
  type: 'circular',
  size: 'medium',
  caption: undefined,
};

FdProgress.propTypes = {
  type: PropTypes.oneOf(['circular', 'linear']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  caption: PropTypes.string,
};

export default FdProgress;
