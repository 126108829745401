import * as Yup from 'yup';

export const initialValues = {
  messages: [],
};

export const validationSchema = Yup.object().shape({
  messages: Yup.array().of(
    Yup.object().shape({
      comment: Yup.string(),
      userName: Yup.string(),
      userId: Yup.string(),
      dateTime: Yup.string(),
      role: Yup.string(),
      messageId: Yup.string(),
      updatedAt: Yup.string(),
      team: Yup.object().shape({}),
    }),
  ),
});
