import * as React from 'react';
import { getIn } from 'formik';
import FdSelect from '../FdSelect';

const fieldToTextField = ({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur
      ?? function (e) {
        fieldOnBlur(e ?? field.name);
      },
    closeOnSelect: true,
    ...field,
    ...props,
  };
};

const FdSelectFormik = (
  { ...props },
) => (
  <FdSelect
    {...fieldToTextField(props)}
  />
);

export default React.memo(FdSelectFormik);
