import { useContext, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useLazyQuery, gql } from '@apollo/client';
import { listProductsByOrgId } from '../../queries/customQueries';
import globalStore from '../../store';
import { AuthContext } from '../../contexts';

const useInitializeProductsStore = () => {
  const snap = useSnapshot(globalStore);
  const { user } = useContext(AuthContext);
  // get available products
  const [fetchProducts] = useLazyQuery(gql(listProductsByOrgId), {
    fetchPolicy: 'network-only',
    skip: !user || Boolean(snap.orgProducts),
    onCompleted: (_data) => {
      // set orgProducts to globalStore
      globalStore.orgProducts =
        _data?.listProductsByOrgId?.items?.map(
          (orgProduct) => orgProduct?.product?.name,
        ) || [];
      globalStore.loading = false;
    },
    onError: (err) => {
      globalStore.loading = false;
      globalStore.error = err;
    },
  });

  useEffect(() => {
    if (!snap.orgProducts) {
      fetchProducts({
        variables: {
          orgId: snap.orgId,
        },
      });
    }
  }, [fetchProducts, snap.orgId, snap.orgProducts]);
};

export default useInitializeProductsStore;
