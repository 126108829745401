import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, Tabs as MuiTabs, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './style';

function Tabs({
  tabs,
  selectedTabIndex,
  labelUpperCase,
  tabsCentered,
  variant,
  linkComponent,
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(selectedTabIndex || 0);
  const flatVariant = variant === 'flat-dense'; // expand logic for more variants

  return (
    <>
      <MuiTabs
        value={selected}
        onChange={(event, newValue) => {
          setSelected(newValue);
        }}
        classes={{
          indicator: classes.tabIndicator,
        }}
        className={flatVariant ? classes.flatCondensed : classes.tabHeader}
        centered={tabsCentered}
      >
        {tabs.map(({
          id, index, name, path,
        }) => (
          <Tab
            component={path && (linkComponent || Link)}
            id={`${index}-${id}-tab`}
            key={`${index}-tabs`}
            aria-controls={`${id}-tab`}
            to={path || ''}
            label={(
              <span
                className={`${selected === index && classes.tabName} ${
                  !labelUpperCase && classes.labelText
                }`}
              >
                {name}
              </span>
            )}
          />
        ))}
      </MuiTabs>

      {tabs.map(({ index, component: children }) => (index === selected ? (
        <Box
          key={`${index}-panel`}
          role="tabpanel"
          p={3}
          id={`full-width-tabpanel-${index}`}
          width="100%"
          className={flatVariant && classes.flatCondensed}
        >
          {children}
        </Box>
      ) : (
        ''
      )))}
    </>
  );
}

Tabs.propTypes = {
  selectedTabIndex: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
        .isRequired,
    }),
  ).isRequired,
  labelUpperCase: PropTypes.bool,
  tabsCentered: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'flat-dense']),
  linkComponent: PropTypes.shape({}),
};

Tabs.defaultProps = {
  labelUpperCase: true,
  tabsCentered: true,
  variant: 'default',
  linkComponent: null,
};

export default Tabs;
