import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useSnapshot } from 'valtio';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Logo from './Logo';
import FdProgress from '../FdProgress';
import FdTooltip from '../FdTooltip';
import useStyles from './styles';
import { useAppTheme, useLocalStorage } from '../../hooks';
import { listUrls } from '../../queries/customQueries';
import { globalStore } from '../../shared';
import FdTypography from '../FdTypography';
import FdLightLogo from './images/fd-light-logo.png';
import FdDarkLogo from './images/fd-dark-logo.png';
import FdLogoSmall from './FdLogoSmall';
import FdDelayed from '../FdDelayed';

const SideNav = ({
  navigationItems,
  handleOnSelectedItem,
  mainPageIds,
  selected,
  selectedParent,
  defaultOpen,
  onDrawerOpenClose,
  MenuHeader,
}) => {
  const classes = useStyles();
  const [openSubMenus, setOpenSubMenus] = useState(
    selectedParent ? { [selectedParent]: true } : {},
  );
  const [selectedItem, setSelectedItem] = useState(selected);
  const [selectedParentItem, setSelectedParentItem] = useState(selectedParent);
  const theme = useAppTheme();
  const headers = document.getElementsByTagName('header');
  if (headers.length > 0) {
    headers[0].style.backgroundColor = theme.palette.background.sidebar;
  }
  const globalSnap = useSnapshot(globalStore);
  const [userPreferencesSidebar, setUserPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar',
    {
      defaultValue: {
        [globalSnap?.userId]: {
          sidebarExpanded: defaultOpen,
        },
      },
    },
  );
  const [open, setOpen] = useState(
    () =>
      userPreferencesSidebar?.[globalSnap?.userId]?.sidebarExpanded ??
      defaultOpen,
  );

  const { data: urlData, loading: urlLoading } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: globalSnap.orgId },
        theme: { eq: globalSnap?.theme?.toUpperCase() },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const setIdsMarginLeft = (ids, width) => {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const id in ids) {
      const element = document.getElementById(ids[id]);
      if (element) {
        element.style.transition = 'margin-left 0.3s ease';
        element.style.marginLeft = width;
      }
    }
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.paddingLeft = width;
    }
  };

  useEffect(() => {
    setIdsMarginLeft(mainPageIds, open ? '240px' : '73px');
    setSelectedItem(selected);
  }, [mainPageIds, open, selected]);

  const handleDrawerOpenClose = () => {
    // call optional drawer open/close event handler
    onDrawerOpenClose?.();
    // click arrow when closed
    if (!open && mainPageIds) {
      setIdsMarginLeft(mainPageIds, '240px');
    } else {
      setIdsMarginLeft(mainPageIds, '73px');
    }
    setOpen(!open);
    setUserPreferencesSidebar({
      ...userPreferencesSidebar,
      [globalSnap?.userId]: {
        sidebarExpanded: !open,
      },
    });
  };

  const handleSubmenuClick = (name) => () => {
    const newSubMenus = { ...openSubMenus };
    if (openSubMenus[name] === undefined) {
      newSubMenus[name] = true;
    }
    newSubMenus[name] = !openSubMenus[name];
    setOpenSubMenus(newSubMenus);
  };

  const handleSelected = (name, path, parentName) => () => {
    handleOnSelectedItem(name, path);

    if (parentName) {
      setSelectedParentItem(parentName);
    } else {
      setSelectedParentItem(null);
    }
  };

  const arrowButton = () => {
    return open ? <ChevronLeftIcon /> : <ChevronRightIcon id="icon-right" />;
  };

  const parentListItemSelected = (name) => {
    if (selectedParentItem) {
      if (selectedParentItem === name) {
        return open
          ? classes.selectedParentItem
          : classes.selectedParentItemClosed;
      }
    }

    return selectedItem === name ? classes.selectedItem : classes.item;
  };

  const listItemIconClass = (name) => {
    if (!open && selectedParentItem === name) {
      return classes.listItemIconSelected;
    }
    if (selectedItem === name) {
      return classes.listItemIconSelected;
    }
    return classes.listItemIcon;
  };

  const BottomLogo = () => {
    return (
      <Box className="text-center">
        <FdTypography variant="captiontext1">Powered by</FdTypography>
        <img
          src={theme.palette.type === 'light' ? FdLightLogo : FdDarkLogo}
          className={classes.logoBottom}
          alt="FifthDomain Logo"
        />
      </Box>
    );
  };
  if (urlLoading) return <FdProgress size="small" />;

  const key = urlData?.listUrls?.items?.[0]?.key;

  const isWhiteLabelledOrgs = key !== undefined;
  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onClick={() => {
          if (!open && mainPageIds) {
            onDrawerOpenClose?.();
            setOpen(true);
            setUserPreferencesSidebar({
              ...userPreferencesSidebar,
              [globalSnap?.userId]: {
                sidebarExpanded: true,
              },
            });
          }
        }}
      >
        <Box className="flex flex-col justify-between h-screen">
          <Box>
            <Box height={225} className="flex items-center justify-center">
              {open ? (
                isWhiteLabelledOrgs ? (
                  <Logo className={classes.logo} logo={key} isWhiteLabelled />
                ) : (
                  <Logo isWhiteLabelled={false} />
                )
              ) : (
                <div className={classes.logoClosed} />
              )}
            </Box>
            <div id="toolbar" className={classes.toolbar}>
              <IconButton
                id="icon-btn"
                onClick={handleDrawerOpenClose}
                classes={{ root: classes.chevron }}
              >
                {arrowButton()}
              </IconButton>
            </div>
            {open && <MenuHeader />}
            <Box
              style={{
                maxHeight: 'calc(100vh - 430px)',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <List className={open ? classes.listOpen : classes.listClosed}>
                {navigationItems.map(
                  ({ name, path, icon, dataCy, children, caption }) => (
                    <React.Fragment key={shortid.generate()}>
                      <ListItem
                        onClick={
                          children
                            ? handleSubmenuClick(name)
                            : handleSelected(name, path)
                        }
                        button
                        className={parentListItemSelected(name)}
                      >
                        <FdTooltip title={name} arrow>
                          <ListItemIcon
                            classes={{
                              root: listItemIconClass(name),
                            }}
                          >
                            {icon}
                          </ListItemIcon>
                        </FdTooltip>
                        {children ? (
                          <ListItemText
                            classes={{
                              primary: open
                                ? classes.heavyText
                                : classes.hidden,
                            }}
                            primary={name}
                            data-cy={dataCy || name}
                          />
                        ) : (
                          <Box className="flex flex-col justify-around">
                            <ListItemText
                              primary={name}
                              classes={{
                                primary: open
                                  ? classes.heavyText
                                  : classes.hidden,
                              }}
                              style={{ margin: caption ? 0 : '4px' }}
                              data-cy={dataCy || name}
                            />
                            {open && caption && (
                              <FdTypography
                                variant="captiontext2"
                                color="secondary"
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '170px',
                                }}
                              >
                                {caption}
                              </FdTypography>
                            )}
                          </Box>
                        )}
                        {open &&
                          children &&
                          (openSubMenus[name] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                      </ListItem>
                      {children && (
                        <>
                          <Collapse
                            in={openSubMenus[name]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {children.map((child) => (
                                <React.Fragment key={shortid.generate()}>
                                  <ListItem
                                    button
                                    className={clsx(
                                      open
                                        ? classes.nested
                                        : classes.nestedClosed,
                                      {
                                        [classes.selectedItem]:
                                          selectedItem === child.name && open,
                                      },
                                    )}
                                    onClick={handleSelected(
                                      child.name,
                                      child.path,
                                      name,
                                    )}
                                  >
                                    {child?.icon && (
                                      <FdTooltip title={child?.name} arrow>
                                        <ListItemIcon
                                          classes={{
                                            root: listItemIconClass(
                                              child?.name,
                                            ),
                                          }}
                                        >
                                          {child?.icon}
                                        </ListItemIcon>
                                      </FdTooltip>
                                    )}
                                    <ListItemText
                                      primary={child.name}
                                      data-cy={child.dataCy || child.name}
                                    />
                                  </ListItem>
                                </React.Fragment>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      )}
                    </React.Fragment>
                  ),
                )}
              </List>
            </Box>
            <Divider />
          </Box>
          <Box>
            {open && (
              <FdDelayed delay={100} hideProgress>
                <Box className={classes.logoBottomContainer}>
                  <BottomLogo />
                </Box>
              </FdDelayed>
            )}
            {!open && <FdLogoSmall />}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

SideNav.propTypes = {
  handleOnSelectedItem: PropTypes.func.isRequired,
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.element,
    }).isRequired,
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainPageIds: PropTypes.array,
  selected: PropTypes.string,
  selectedParent: PropTypes.string,
  defaultOpen: PropTypes.bool,
  onDrawerOpenClose: PropTypes.func,
  MenuHeader: PropTypes.node,
};

SideNav.defaultProps = {
  mainPageIds: [],
  selected: null,
  selectedParent: null,
  defaultOpen: true,
  onDrawerOpenClose: undefined,
  MenuHeader: () => <></>,
};

export default SideNav;
