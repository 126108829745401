import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwt from 'jsonwebtoken';
import { getAccessToken, setAccessToken } from './tokenTempStorage';

const apolloRefreshLink = ({ baseApiAddress, setGlobalToken }) => {
  const refreshLink = new TokenRefreshLink({
    accessTokenField: 'payload',
    isTokenValidOrUndefined: () => {
      const token = getAccessToken();
      // No need to refresh if token exists and is valid
      if (token && jwt.decode(token)?.exp * 1000 > Date.now()) {
        return true;
      }
      return false;
    },
    fetchAccessToken: () => fetch(`${baseApiAddress}/auth/refresh`, {
      method: 'POST',
      credentials: 'include',
    }),
    handleFetch: (newToken) => {
      setAccessToken(newToken);
      setGlobalToken(newToken);
    },
    handleError: () => {
      // window.location.replace('/a/login');
    },
  });
  return refreshLink;
};

export default apolloRefreshLink;
