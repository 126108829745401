import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { listUrls } from '../../queries/customQueries';
import FdProgress from '../FdProgress';
import Logo from '../SideNav/Logo';
import LogoDark from '../SideNav/LogoDark';
import useStyles from '../SideNav/styles';
import { useOrgId, useAppTheme } from '../../hooks';

const FdOrgLogo = ({ isIcon }) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { orgId, userLoading, userData } = useOrgId();

  const { data: urlData, loading: urlLoading } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: orgId },
        theme: { eq: userData?.theme },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (userLoading || urlLoading) return <FdProgress size="small" />;

  const key = urlData?.listUrls?.items?.[0]?.key;

  const isWhiteLabelledOrgs = key !== undefined;

  const logo = () => {
    if (theme.palette.type === 'light') {
      return (
        <Logo
          className={classes.logo}
          isWhiteLabelled={false}
          isIcon={isIcon}
        />
      );
    }
    return <LogoDark className={classes.logo} />;
  };

  return isWhiteLabelledOrgs ? (
    <Logo className={classes.logo} logo={key} isWhiteLabelled />
  ) : (
    logo()
  );
};

FdOrgLogo.propTypes = {
  isIcon: PropTypes.bool,
};

FdOrgLogo.defaultProps = {
  isIcon: false,
};

export default FdOrgLogo;
