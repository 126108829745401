import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import globalStore from '../../store';

const useInitializeAAFCStore = () => {
  const snap = useSnapshot(globalStore);

  useEffect(() => {
    // AAFC org
    const aafcOrg =
      document.location.host.includes('localhost') ||
      document.location.host.includes('test.')
        ? 'd162c177-40c0-4367-a6f1-42c8d061bc3f' // consider as AAFC org for testing purpose
        : '58dfd69f-73dd-47b9-b54e-9d9b25c98993'; // AAFC org in Prod

    globalStore.isAAFCOrg = snap.orgId === aafcOrg;
  }, [snap.orgId]);
};

export default useInitializeAAFCStore;
