import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import FdTextField from '../FdTextField';
import FdIconsV5 from '../FdIconsV5';
import MentionsPopper from './MentionsPopper';
import { getMentions, replaceAtSymbolInSentence } from './utils';
import { createImageFileFromUrl } from '../../shared/image';
import EmojiPickerPopper from './EmojiPickerPopper';

const useStyles = makeStyles(() => ({
  cancel: {
    color: '#fff',
    backgroundColor: 'rgba(189, 189, 189, 1)',
    '&:hover': {
      backgroundColor: 'rgba(141, 141, 141, 1)',
    },
  },
  save: {
    color: '#fff',
    backgroundColor: 'rgba(76, 90, 255, 1)',
    '&:hover': {
      backgroundColor: 'rgba(36, 32, 226, 1)',
    },
  },
}));

const EditComment = ({
  onSave,
  userList,
  currentMessage,
  onCancel,
  shortMode,
}) => {
  const [chatMessage, setChatMessage] = useState('');
  const [imagePreview, setImagePreview] = useState(undefined);
  const [imageFile, setImageFile] = useState(undefined);
  const [showMentionsPicker, setShowMentionsPicker] = useState(false);
  const [mentionText, setMentionText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(userList);
  const [textFieldCursorPosition, setTextFieldCursorPosition] = useState(null);
  const { Cancel } = FdIconsV5;
  const textFieldRef = useRef(null);
  const boxRef = useRef(null);
  const classes = useStyles();
  const { Done, Close } = FdIconsV5;

  useEffect(() => {
    setChatMessage(currentMessage?.comment);
    setImagePreview(currentMessage?.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.style.height = `${imagePreview ? 150 : 110}px`;
    }
  }, [imagePreview]);

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    // Get image from clipboard
    if (clipboardData && clipboardData.items) {
      for (let i = 0; i < clipboardData.items.length; i += 1) {
        const item = clipboardData.items[i];
        if (item.type.indexOf('image') !== -1) {
          const imageBlob = item.getAsFile();
          setImageFile(imageBlob);
          const reader = new FileReader();
          reader.onload = (e) => {
            setImagePreview(e.target.result);
          };
          reader.readAsDataURL(imageBlob);
          break;
        }
      }
    }
  };

  // Drag and drop of image
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setImageFile(droppedFile);
    if (droppedFile.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(droppedFile);
    }
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    setChatMessage(text);
    const cursorPosition = textFieldRef.current.selectionStart;
    if (cursorPosition === null) return;
    setTextFieldCursorPosition(cursorPosition);
    const { mentions: newMentions } = getMentions(text, cursorPosition);
    setMentionText(newMentions[0]);

    if (newMentions[0]) {
      // Filter list based on searchText (Sample data used here)
      const filteredItems = userList?.filter((item) =>
        item?.alias.toLowerCase().includes(newMentions[0]?.toLowerCase()),
      );
      setFilteredUsers(filteredItems);
    }
    if (newMentions[0] === '') {
      setFilteredUsers(userList);
    }
    setShowMentionsPicker(newMentions.length > 0 ? textFieldRef.current : null);
  };

  const chatMessageCharLength = chatMessage?.trim()?.length || 0;
  const emptyChatMessage = chatMessageCharLength === 0;
  const chatMessageLimitReached = chatMessageCharLength > 500;

  const mentionReset = () => {
    setFilteredUsers(userList);
    setShowMentionsPicker(null);
    textFieldRef.current.focus();
  };

  const onMentionSelection = (u) => {
    setChatMessage((msg) => {
      const text = replaceAtSymbolInSentence(
        msg,
        `@[${u?.alias}] `,
        `@${mentionText}`,
      );
      return text;
    });
    setFilteredUsers(userList);
    setShowMentionsPicker(null);
    textFieldRef.current.focus();
  };

  const onReactionClick = (emoji) => {
    if (textFieldRef.current) {
      const currentValue = textFieldRef.current.value;
      const newValue =
        currentValue.substring(0, textFieldCursorPosition) +
        emoji +
        currentValue.substring(textFieldCursorPosition);
      textFieldRef.current.value = newValue;
      setChatMessage(newValue);
    }
  };

  return (
    <Box className="flex flex-col" mt={1} ref={boxRef}>
      <Box className="mt-3">
        <FdTextField
          inputRef={textFieldRef}
          id="comment-field"
          placeholder="Type a message"
          rows={2}
          value={chatMessage}
          onChange={handleInputChange}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onPaste={handlePaste}
          error={chatMessageLimitReached}
          helperText={
            chatMessageLimitReached &&
            'Please limit your message to 500 characters or less.'
          }
          fullWidth
          multiline
        />
        {userList?.length > 0 && (
          <MentionsPopper
            onSelection={onMentionSelection}
            onClickAway={mentionReset}
            filteredUsers={filteredUsers}
            open={Boolean(showMentionsPicker)}
            anchorEl={showMentionsPicker}
          />
        )}
      </Box>
      <Box className="flex items-start justify-between w-full">
        <Box className="flex items-start gap-x-6 mt-2" height={80}>
          {imagePreview && (
            <Box my={1}>
              <Badge
                badgeContent={<Cancel />}
                onClick={() => setImagePreview(null)}
                className="cursor-pointer"
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '10px',
                  }}
                />
              </Badge>
            </Box>
          )}
          {imagePreview && (
            <Divider variant="vertical" style={{ height: '70px' }} />
          )}
          {!chatMessageLimitReached && (
            <EmojiPickerPopper
              onEmojiSelection={onReactionClick}
              shortMode={shortMode}
              editMode
            />
          )}
        </Box>
        <Box className="flex gap-x-2">
          <Box
            className={`${classes.cancel} flex items-center justify-center px-2 rounded-xl mt-2 cursor-pointer`}
            onClick={() => onCancel()}
          >
            <Close style={{ height: '20px', width: '20px' }} />
          </Box>
          <Box
            className={`${classes.save} flex items-center justify-center px-2 rounded-xl mt-2 cursor-pointer`}
            onClick={async () => {
              if (emptyChatMessage || chatMessageLimitReached) {
                return;
              }
              let imageToSend = imageFile;
              // if the file source is a url, then fetch image
              if (imagePreview && !imageFile) {
                const imageAsFile = await createImageFileFromUrl(
                  currentMessage?.image,
                  'newImageFromUrl',
                );
                imageToSend = imageAsFile;
              }
              onSave(chatMessage, imagePreview, imageToSend);
            }}
          >
            <Done style={{ height: '20px', width: '20px' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

EditComment.propTypes = {
  onSave: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentMessage: PropTypes.shape({
    comment: PropTypes.string,
    image: PropTypes.shape({}),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  shortMode: PropTypes.bool.isRequired,
};

export default EditComment;
