// Check if the local part of an email contains invalid characters.
// If it does, return the the regex matches
function invalidLocalPart(email) {
  // Ensure there are no invalid special characters before @
  // Taken from chrome email regex
  const localPart = email.substring(0, email.indexOf('@'));
  return localPart.match(/[^a-z0-9!#$%&'*+/=?^_`{|}~.-]/gi);
}

function validEmail(email) {
  // Ensure the overall email is valid by RFC 5322 official standards
  // It still have a success rate of over 95%.
  return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
}

export const emailHelp = (email) => {
  const { length } = email;

  if (email === '') {
    return null;
  }

  if (email.charAt(0) === ' ') {
    return 'Please remove the Leading space.';
  }

  if (email.charAt(length - 1) === ' ') {
    return 'Please remove the Trailing space.';
  }

  if ((email.match(/@/g) || []).length !== 1) {
    return "There should be one '@' symbol.";
  }

  if (email.substring(email.indexOf('@') + 1, length) === '') {
    return "There must be something after the '@' symbol";
  }

  const badChars = invalidLocalPart(email);
  if (badChars) {
    return `The part followed by @ should not contain ${badChars[0]}`;
  }

  if (!validEmail(email)) {
    return 'Enter a valid email.';
  }

  return null;
};

export const emailValidation = (email) => {
  if (email === '') {
    return true;
  }
  return emailHelp(email) === null;
};
