import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import FdIconsV5 from '../FdIconsV5';
import FdModal from '../FdModal';
import FdTypography from '../FdTypography';

const useStyles = makeStyles(() => ({
  edit: {
    color: '#fff',
    backgroundColor: 'rgba(151, 180, 255, 1)',
    '&:hover': {
      backgroundColor: 'rgba(110, 132, 255, 1)',
    },
  },
  delete: {
    color: '#fff',
    backgroundColor: 'rgba(229, 115, 135, 1)',
    '&:hover': {
      backgroundColor: 'rgba(198, 40, 40, 1)',
    },
  },
}));

const IconButton = ({ children }) => (
  <Box className="flex items-center justify-center px-2 rounded-xl mt-2">
    {children}
  </Box>
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
};

const MessageToolbar = ({
  onDelete,
  onDeleteCancel,
  deleteModalContent,
  setEditMode,
  adminView,
  messageOwner,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const classes = useStyles();
  const { DeleteOutlined, ModeEditOutlineOutlined } = FdIconsV5;

  return (
    <Box className="self-end">
      <Box className="flex gap-x-2">
        {messageOwner && (
          <Box
            className={`${classes.edit} flex items-center justify-center px-2 rounded-xl mt-2 cursor-pointer`}
            onClick={() => setEditMode(true)}
          >
            <ModeEditOutlineOutlined
              style={{ height: '20px', width: '20px' }}
            />
          </Box>
        )}
        {(adminView || messageOwner) && (
          <Box
            className={`${classes.delete} flex items-center justify-center px-2 rounded-xl mt-2 cursor-pointer`}
            onClick={() => setOpenDeleteModal(true)}
          >
            <DeleteOutlined style={{ height: '20px', width: '20px' }} />
          </Box>
        )}
      </Box>
      <FdModal
        size="md"
        title="Are you sure you want to delete this message?"
        open={openDeleteModal}
        confirm="Confirm"
        dismiss="Cancel"
        onConfirm={() => {
          onDelete();
          setOpenDeleteModal(false);
        }}
        onDismiss={() => {
          onDeleteCancel();
          setOpenDeleteModal(false);
        }}
        data-cy="delete-modal"
      >
        <Box>
          <FdTypography variant="body2" color="secondary">
            Once deleted, this message will become inaccessible to all users and
            cannot be recovered.
          </FdTypography>
          {deleteModalContent}
        </Box>
      </FdModal>
    </Box>
  );
};

MessageToolbar.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDeleteCancel: PropTypes.func.isRequired,
  deleteModalContent: PropTypes.node.isRequired,
  setEditMode: PropTypes.func.isRequired,
  adminView: PropTypes.bool.isRequired,
  messageOwner: PropTypes.bool.isRequired,
};

export default MessageToolbar;
