import React, { useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import {
  Box,
  ClickAwayListener,
  Tooltip,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import FdIconsV5 from '../FdIconsV5';

const useStyles = makeStyles((theme) => ({
  iconHover: {
    '&:hover': {
      color: theme.palette.type === 'dark' ? 'rgba(76, 90, 255, 1)' : 'maroon',
    },
  },
  iconButtonHover: {
    transform: 'rotate(20deg)',
    transition: 'transform 0.2s',
  },
}));

const EmojiPickerPopper = ({ onEmojiSelection, editMode, shortMode }) => {
  const [showReactionsPicker, setShowReactionsPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();
  const { AddReactionOutlined } = FdIconsV5;

  const handleReactionClick = (emojiObject) => {
    const { emoji } = emojiObject;
    setShowReactionsPicker(false);
    setAnchorEl(null);
    onEmojiSelection(emoji);
  };

  return (
    <Box>
      <ClickAwayListener
        onClickAway={() => {
          setShowReactionsPicker(false);
          setAnchorEl(null);
        }}
      >
        <Box>
          <Tooltip title="Add reaction">
            <span>
              <IconButton
                size="small"
                edge="start"
                onClick={(event) => {
                  setShowReactionsPicker(true);
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`${isHovered && classes.iconButtonHover}`}
              >
                <AddReactionOutlined
                  className={isHovered ? classes.iconHover : ''}
                />
              </IconButton>
            </span>
          </Tooltip>
          {showReactionsPicker && (
            <EmojiPicker
              onEmojiClick={handleReactionClick}
              skinTonesDisabled
              categories={[
                'suggested',
                'smileys_people',
                'food_drink',
                'travel_places',
              ]}
              previewConfig={{
                showPreview: false,
              }}
              style={{
                border: '1px solid #000',
                position: 'absolute',
                bottom: editMode ? '215px' : '130px',
                left: shortMode ? '41px' : 'auto',
              }}
              width={shortMode ? 230 : 350}
              height={shortMode ? 300 : 450}
              lazyLoadEmojis
            />
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

EmojiPickerPopper.propTypes = {
  onEmojiSelection: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  shortMode: PropTypes.bool,
};

EmojiPickerPopper.defaultProps = {
  editMode: false,
  shortMode: false,
};

export default EmojiPickerPopper;
