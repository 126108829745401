export const getUserType = ({
  userPreferencesSidebarMode,
  hasManagerialPermission,
}) => {
  const mode =
    userPreferencesSidebarMode ??
    (hasManagerialPermission ? 'Manage' : 'Participate');
  return hasManagerialPermission
    ? mode === 'Manage'
      ? 'ADMIN'
      : 'PARTICIPANT'
    : 'PARTICIPANT';
};
