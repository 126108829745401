import { makeStyles } from '@material-ui/core/styles';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles((theme) => ({
  root: ({ rowClickable }) => ({
    ...(theme?.palette?.type === 'dark' && {
      scrollbarWidth: 'thin', // For Firefox
      scrollbarColor: 'rgba(129, 129, 129, 1) rgba(78, 78, 80, 1)', // For Firefox
      '&::-webkit-scrollbar': {
        width: '8px', // For WebKit-based browsers
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(129, 129, 129, 1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(78, 78, 80, 1)',
      },
    }),
    background: theme.palette.background.paper,
    color: theme.palette.typography.primary || ColorPalette.black00,
    '& [class*="MuiDataGrid-cell"]': {
      whiteSpace: 'pre',
    },
    '& [class*="MuiDataGrid-columnHeader"]': {
      fontSize: '14px',
      fontWeight: 600,
    },
    '& [class*="MuiDataGrid-cellLeft"]': {
      paddingLeft: '16px',
    },
    '& [class*="MuiDataGrid-columnsContainer"]': {
      borderBottom: `1px solid ${theme.palette.table.border}`,
    },
    '& [class*="pinnedColumnHeaders"]': {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper,
      backgroundImage:
        theme.palette.type === 'dark' ? 'none' : theme.palette.table.hover,
    },
    '& [class*="pinnedColumns"]': {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.paper,
      backgroundImage:
        theme.palette.type === 'dark' ? 'none' : theme.palette.table.hover,
    },
    '& [class*="MuiDataGrid-row"]': {
      '&:hover': {
        backgroundColor: theme.palette.table.hover,
        cursor: rowClickable ? 'pointer' : 'default',
      },
      '&[class*="Mui-selected"]': {
        backgroundColor: theme.palette.table.selected,
        '&:hover': {
          backgroundColor: theme.palette.table.hover,
        },
      },
    },
    '& [class*="MuiCheckbox-root"]': {
      color: theme.palette.inputs.selectBorder,
    },
    '& [class*="MuiCheckbox-colorPrimary"][class*="Mui-checked"]': {
      color: theme.palette.secondary.main,
    },
    '& [class*="MuiTablePagination-root"]': {
      color: theme.palette.typography.primary,
    },
    '& [class*="MuiSelect-icon"]': {
      color: theme.palette.typography.primary,
    },
    '& [class*="MuiIconButton-root"]': {
      color: theme.palette.typography.primary,
    },
    '& [class*="MuiDataGrid-footer"]': {
      height: '64px',
    },
    '& [class*="MuiDataGrid-main"]': {
      borderTop:
        theme.palette.type === 'dark'
          ? `1px solid ${theme.palette.table.border}`
          : 'inherit',
    },
    '& [class*="MuiDataGrid-withBorderColor"]': {
      borderColor:
        theme.palette.type === 'dark'
          ? `${theme.palette.table.border} !important`
          : 'inherit',
    },
  }),
  styledToolbar: {
    backgroundColor: theme.palette.table.selected,
  },
  styledSelect: {
    '& [class*="MuiOutlinedInput"]': {
      padding: 0,
    },
  },
  tableBorder: {
    '& [class*="MuiDataGrid-columnHeaders"][class*="MuiDataGrid-withBorderColor"]':
      {
        border: 'none',
      },
    '& [class*="MuiDataGrid-cell"][class*="MuiDataGrid-withBorderColor"]': {
      border: 'none',
    },
    '& [class*="MuiDataGrid-cell"][class*="MuiDataGrid-cell--textLeft"][class*="MuiDataGrid-withBorderColor"]':
      {
        border: 'none',
      },
  },
  toolbarButton: {
    color: 'rgb(110, 132, 255) !important',
    padding: '4px 5px !important',
  },
  headerContainerContent: {
    width: '100%',
  },
}));

export default useStyles;
