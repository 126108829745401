import { useEffect } from 'react';

// Set the page title to a value and revert title on unmount
const useTitle = (title) => {
  useEffect(() => {
    const oldTitle = document.title;
    if (title) {
      document.title = title;
    }
    return () => {
      document.title = oldTitle;
    };
  }, [title]);
};

export default useTitle;
