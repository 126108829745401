const tokenStore = () => {
  let token = null;

  const getToken = () => token;

  const setToken = (tok) => {
    token = tok;
  };

  const deleteToken = () => {
    token = null;
  };

  return {
    deleteToken,
    getToken,
    setToken,
  };
};

export default tokenStore();
