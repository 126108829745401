import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { gridRowsLookupSelector } from '@mui/x-data-grid-pro';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import FdDropdownMenu from '../FdDropdownMenu';

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'left',
};

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'left',
};

function ActionMenu({ apiRef, rowId, actions }) {
  const [anchor, setAnchor] = useState(null);
  const idRowsLookup = gridRowsLookupSelector(apiRef.current.state);

  const actionsDict = _.chain(actions)
    .map(({ label, onClick }) => [label, onClick])
    .fromPairs()
    .value();
  const menuOptions = actions
    .filter(({ show }) => {
      const result = show(idRowsLookup[rowId]);
      return result;
    })
    .map(({ label }) => ({ id: label, name: label }));

  const menuCallback = (event) => {
    const { id } = event.target;
    actionsDict[id](idRowsLookup[rowId]);
  };

  return (
    <>
      <Box mx={1} />
      {menuOptions.length > 0 && (
        <IconButton
          onClick={(event) => setAnchor(event.currentTarget)}
          data-cy="actions-menu"
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <FdDropdownMenu
        id="0"
        menuLabel="Actions Menu"
        transformOrigin={TRANSFORM_ORIGIN}
        anchorOrigin={ANCHOR_ORIGIN}
        variant="medium"
        anchor={anchor}
        setAnchor={setAnchor}
        menuCallback={menuCallback}
        menuOptions={menuOptions}
        closeOnSelect
      />
    </>
  );
}

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  apiRef: PropTypes.shape({ current: PropTypes.shape() }).isRequired,
  rowId: PropTypes.string.isRequired,
};

export default ActionMenu;
