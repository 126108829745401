import _ from 'lodash';

export const isObjectArray = (arr) => {
  if (Array.isArray(arr)) {
    return arr.every(
      (element) => typeof element === 'object' && element !== null,
    );
  }
  return false;
};

export const isArrayWithKeys = (arr, ...keys) => {
  return (
    _.isArray(arr) &&
    _.every(arr, (item) => {
      return _.isObject(item) && keys.every((key) => _.has(item, key));
    })
  );
};

// getArrayByLength(3, `SlNo: ${i + 1}`) returns ['SlNo: 1', 'SlNo: 2', 'SlNo: 3']
export const getArrayByLength = (_length, formatFn = (__, i) => i) =>
  Array.from(Array(Number(_length)), formatFn);

export default isObjectArray;
