import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, IconButton, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {
  DataGridPro,
  GridOverlay,
  getGridStringOperators,
  useGridApiRef,
  gridRowsLookupSelector,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { green } from '@material-ui/core/colors';
import _ from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Person, Group, InfoOutlined } from '@material-ui/icons';
import useStyles from './styles';
import { removeSpecialChars } from '../../shared';
import Chip from '../FdChip';
import Button from '../FdButton';
import ActionMenu from './ActionMenu';
import Toolbar from './Toolbar';
import FdTooltip from '../FdTooltip';
import FdTypography from '../FdTypography';

const FdTable = ({
  id,
  rows,
  columns,
  selection,
  rowsPerPageOptions,
  setSelected,
  toolbarSettings,
  defaultMuiToolbarSettings,
  actions,
  fixedHeaders,
  noRowsMessage,
  noResultsMessage,
  pagination,
  tablePageSize,
  disableSelectionOnClick,
  checkboxSelectionVisibleOnly,
  rowClickable,
  isRowSelectable,
  showRowsOnlyOnSearch,
  gridId,
  columnVisibilityModel,
  ...props
}) => {
  const apiRef = useGridApiRef();
  const theme = useTheme();
  const classes = useStyles({ rowClickable });
  const [pageSize, setPageSize] = useState(tablePageSize);
  const [tableRows, setTableRows] = useState([]);
  const [page, setPage] = useState(0);
  const [randomText] = useState(shortid.generate());
  const [_columnVisibilityModel, _setColumnVisibilityModel] = useState(
    columnVisibilityModel,
  );

  const CustomNoRowsOverlay = () => <GridOverlay>{noRowsMessage}</GridOverlay>;
  const CustomNoResultsOverlay = () => (
    <GridOverlay>{noResultsMessage}</GridOverlay>
  );
  const addActions = !_.isEmpty(actions);
  // on showRowsOnlyOnSearch enabled apply a quick filter
  // with a random term to avoid showing any rows
  const onFilterModelChange = useCallback(
    (value) => {
      apiRef.current.setQuickFilterValues(
        value.split(' ').filter((word) => word !== ''),
      );
    },
    [apiRef],
  );

  useEffect(() => {
    if (tableRows.length !== rows.length) {
      // if there is a change in rows, before re-render clear quick filter
      apiRef.current.setQuickFilterValues('');
    }
    setTableRows(rows);
  }, [rows, tableRows.length, apiRef]);

  useEffect(() => {
    if (showRowsOnlyOnSearch) {
      onFilterModelChange(randomText);
    }
  }, [onFilterModelChange, randomText, showRowsOnlyOnSearch]);

  const actionColumnWidth = _.maxBy(actions, 'width')?.width;
  const actionColumnProps = actionColumnWidth
    ? { width: _.maxBy(actions, 'width')?.width }
    : {};
  const finalColumns = selection
    ? [
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          sortable: true,
          width: 60,
        },
        ...columns,
      ]
    : columns;

  const CustomToolbar = () =>
    toolbarSettings.hide ? null : (
      <Toolbar
        apiRef={apiRef}
        settings={{ ...toolbarSettings, actions, selection }}
        defaultMuiToolbarSettings={defaultMuiToolbarSettings}
      />
    );

  const RestrictedInputValue = (restrictedProps) => {
    const { item, applyValue } = restrictedProps;

    const handleFilterChange = (event) => {
      applyValue({ ...item, value: removeSpecialChars(event.target.value) });
    };

    return (
      <TextField
        name="custom-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
        label="Value"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };
  const stringFilterOperators = getGridStringOperators()
    .filter((operator) => operator.value !== 'isAnyOf') // remove isAnyOf from filter options
    .map((operator, index) => ({
      ...operator,
      InputComponent: RestrictedInputValue,
      key: index,
    }));
  const columnsLength = finalColumns?.length ?? 0;
  const tableReadyColumns = _.chain(finalColumns)
    // restrict special characters from search field
    .map((col, index) => {
      const filterOperators =
        col.type === 'number'
          ? undefined
          : { filterOperators: stringFilterOperators };
      return {
        ...col,
        sortable: col.unSort ? !col.unSort : true,
        ...filterOperators,
        key: index,
      };
    })
    // Add renderCell for functions
    .map((col, index) => {
      if (col.renderType === 'chip') {
        return {
          ...col,
          key: index,
          renderCell: ({ row, value }) => {
            const label = _.isObject(value) ? value.label : value;

            const variant = _.isObject(value)
              ? value.variant
              : row?.status?.variant;
            return <Chip color={variant} label={label} size="small" />;
          },
        };
      }
      if (col.renderType === 'booleanIcon') {
        return {
          ...col,
          renderCell: (params) => {
            // eslint-disable-next-line react/destructuring-assignment
            if (params?.value) {
              return <CheckIcon style={{ color: green[500] }} />;
            }
            return <CloseIcon color="error" />;
          },
        };
      }
      if (col.renderType === 'icon') {
        return {
          ...col,

          renderCell: ({ value }) => {
            const label = _.isObject(value) ? value.label : value;
            if (label === 'Team') {
              return (
                <Box alignItems="center" display="flex">
                  <Group style={{ marginRight: '12px' }} /> Team
                </Box>
              );
            }
            return (
              <Box alignItems="center" display="flex">
                <Person style={{ marginRight: '12px' }} /> Individual
              </Box>
            );
          },
        };
      }
      if (col.renderType === 'func') {
        return {
          ...col,
          renderCell: col.renderFunc,
        };
      }
      // apply tooltip if sent with a custom/default icon on column header
      if (col?.headerTooltip) {
        return {
          ...col,
          renderHeader: (params) => {
            const headerName = params.colDef?.headerName;
            return (
              <>
                <FdTypography variant="subtitle2" aria-label={headerName}>
                  {headerName}
                </FdTypography>
                <FdTooltip title={col?.headerTooltip}>
                  <IconButton aria-label="header-custom-icon">
                    {col?.headerTooltipIcon || <InfoOutlined />}
                  </IconButton>
                </FdTooltip>
              </>
            );
          },
        };
      }
      // add a flex 1 to the last column to extend the table to full width
      if (addActions && columnsLength === index + 1) {
        return {
          ...col,
          flex: 1,
        };
      }
      return col;
    })
    // Add Actions Column if Required
    .concat(
      addActions
        ? {
            field: 'Actions',
            type: 'actions',
            headerName: null,
            filterable: false,
            disableExport: true,
            sortable: false,
            renderHeader: () => {
              return (
                <FdTypography
                  variant="subtitle2"
                  className="flex w-full justify-center"
                >
                  Actions
                </FdTypography>
              );
            },
            // disableClickEventBubbling: true,
            renderCell: (params) => {
              // eslint-disable-next-line no-shadow
              const { id } = params;
              const idRowsLookup = gridRowsLookupSelector(apiRef.current.state);
              return (
                <Box display="flex" alignItems="center" width="100%">
                  {actions?.length > 2 ? (
                    <ActionMenu apiRef={apiRef} rowId={id} actions={actions} />
                  ) : (
                    actions?.map(({ label, onClick, CustomElement }, index) => (
                      <Box mx={1} key={index} width="100%">
                        {CustomElement ? (
                          <CustomElement
                            rowData={{
                              ...idRowsLookup[id],
                            }}
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              onClick(idRowsLookup[id]);
                            }}
                            variant="tertiary"
                          >
                            {label}
                          </Button>
                        )}
                      </Box>
                    ))
                  )}
                </Box>
              );
            },
            ...actionColumnProps,
          }
        : [],
    )
    .value();

  return (
    <DataGridPro
      {...props}
      sx={{
        borderColor: theme?.palette?.table?.border,
      }}
      columnVisibilityModel={_columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        _setColumnVisibilityModel(newModel)
      }
      className={` ${props.noBorderDisplay && classes.tableBorder}`}
      data-cy={id}
      apiRef={apiRef}
      rows={tableRows}
      columns={tableReadyColumns}
      pageSize={props.autoPageSize ? undefined : pageSize}
      pageSizeOptions={rowsPerPageOptions}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize || 10,
            page: 0,
          },
        },
        pinnedColumns: { right: ['Actions'] },
      }}
      checkboxSelection={selection}
      pagination={pagination}
      disableRowSelectionOnClick={disableSelectionOnClick}
      checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
      experimentalFeatures={{ newEditingApi: true }}
      slots={{
        toolbar: CustomToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        noResultsOverlay: CustomNoResultsOverlay,
      }}
      onPageSizeChange={(_pageSize) => {
        setPageSize(_pageSize);
        setSelected([]);
      }}
      onPageChange={(_page) => {
        setPage(_page);
        setSelected([]);
      }}
      classes={{
        root: classes.root,
        columnHeaderTitleContainerContent: classes.headerContainerContent,
      }}
      page={page}
      disableColumnReorder={fixedHeaders}
      disableColumnMenu
      isRowSelectable={isRowSelectable}
      rowSelectionModel={props?.selectionModel}
      onRowSelectionModelChange={props?.onSelectionModelChange}
      onFilterModelChange={(v) => {
        if (showRowsOnlyOnSearch) {
          // filters are applied and quickFilterTerm is pre-generated random
          // then clear the random quick filter to apply other user given filters
          if (v.items.length > 0 && v.quickFilterValues[0] === randomText) {
            onFilterModelChange('');
          }
          // if no quick search term or no filters applied,
          // then hide rows if showRowsOnlyOnSearch is enabled
          const noQuickSearchTerm =
            v.quickFilterValues.length === 0 && v.items.length === 0;
          if (noQuickSearchTerm) {
            onFilterModelChange(randomText);
          }
        }
      }}
      componentsProps={{ panel: { disablePortal: true } }}
    />
  );
};

FdTable.defaultProps = {
  id: 'table',
  selection: false,
  visibleSelection: undefined,
  fixedHeaders: false,
  pagination: true,
  toolbarSettings: {
    hide: false,
    title: '',
    titleTooltip: undefined,
    titleTooltipIcon: undefined,
    downloadButton: false,
    columnButton: false,
    filterButton: false,
    searchBox: false,
    bulkActions: false,
  },
  defaultMuiToolbarSettings: {
    showMuiDefaultToolbar: true,
    columnsButton: false,
    filterButton: true,
    densityButton: true,
    exportButton: false,
  },
  actions: [],
  noRowsMessage: 'No items to display',
  noResultsMessage: 'No results found',
  setSelected: () => {},
  disableSelectionOnClick: false,
  rowsPerPageOptions: [5, 10, 20],
  tablePageSize: 5,
  row: {},
  value: {},
  checkboxSelectionVisibleOnly: true,
  rowClickable: false,
  rowHeight: 52,
  autoPageSize: false,
  noBorderDisplay: false,
  onSelectionModelChange: undefined,
  selectionModel: [],
  isRowSelectable: () => true,
  showRowsOnlyOnSearch: false,
  gridId: '',
  columnVisibilityModel: {},
};

FdTable.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string,
      description: PropTypes.string,
      flex: PropTypes.number,
      width: PropTypes.number,
      hide: PropTypes.bool,
      resizable: PropTypes.bool,
      sort: PropTypes.bool,
      type: PropTypes.oneOf([
        'string',
        'date',
        'number',
        'dateTime',
        'chip',
        'tick',
      ]),
      renderType: PropTypes.oneOf(['chip', 'booleanIcon', 'icon', 'func']),
      renderFunc: PropTypes.func,
      headerTooltip: PropTypes.node,
      headerTooltipIcon: PropTypes.node,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      difficulty: PropTypes.string,
      category: PropTypes.string,
      recommendedPoints: PropTypes.number,
      solveTime: PropTypes.number,
    }),
  ).isRequired,
  selection: PropTypes.bool,
  fixedHeaders: PropTypes.bool,
  pagination: PropTypes.bool,
  toolbarSettings: PropTypes.shape({
    hide: PropTypes.bool,
    title: PropTypes.node,
    downloadButton: PropTypes.bool,
    columnButton: PropTypes.bool,
    filterButton: PropTypes.bool,
    searchBox: PropTypes.bool,
    bulkActions: PropTypes.bool,
    headerActions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
  }),
  defaultMuiToolbarSettings: PropTypes.shape({
    showMuiDefaultToolbar: PropTypes.bool,
    columnsButton: PropTypes.bool,
    filterButton: PropTypes.bool,
    densityButton: PropTypes.bool,
    exportButton: PropTypes.bool,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  noRowsMessage: PropTypes.node,
  noResultsMessage: PropTypes.node,
  disableSelectionOnClick: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  visibleSelection: PropTypes.bool,
  setSelected: PropTypes.func,
  row: PropTypes.object,
  value: PropTypes.object,
  tablePageSize: PropTypes.number,
  checkboxSelectionVisibleOnly: PropTypes.bool,
  rowClickable: PropTypes.bool,
  rowHeight: PropTypes.number,
  autoPageSize: PropTypes.bool,
  noBorderDisplay: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  selectionModel: PropTypes.arrayOf(PropTypes.shape({})),
  showRowsOnlyOnSearch: PropTypes.bool,
  gridId: PropTypes.string,
  columnVisibilityModel: PropTypes.object,
};

export default FdTable;
