import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import clsx from 'clsx';
import { ColorPalette } from '../shared/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 2),
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  collapseWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
  },
  default: {
    backgroundColor: ColorPalette.lightGrey00,
    color: theme.palette.common.black,
  },
  success: {
    backgroundColor: ColorPalette.lightGreen00,
    color: theme.palette.common.black,
  },
  error: {
    backgroundColor: ColorPalette.lightRed00,
    color: theme.palette.common.black,
  },
  warning: {
    backgroundColor: ColorPalette.lightYellow00,
    color: theme.palette.common.black,
  },
  info: {
    backgroundColor: ColorPalette.lightBlue02,
    color: theme.palette.common.black,
  },
  successIcon: { color: ColorPalette.green02, marginRight: '8px' },
  errorIcon: { color: ColorPalette.red00, marginRight: '8px' },
  warningIcon: { color: ColorPalette.orange00, marginRight: '8px' },
  infoIcon: { color: ColorPalette.blue05, marginRight: '8px' },
  defaultIcon: { color: ColorPalette.grey09, marginRight: '8px' },
}));

const withSnackbarProvider = (Component) => (props) => {
  const classes = useStyles();

  const iconMap = {
    default: <RadioButtonUncheckedIcon className={classes.defaultIcon} />,
    info: <InfoOutlinedIcon className={classes.infoIcon} />,
    success: <CheckCircleOutlineIcon className={classes.successIcon} />,
    warning: <ReportProblemOutlinedIcon className={classes.warningIcon} />,
    error: <ErrorOutlineIcon className={classes.errorIcon} />,
  };

  return (
    <SnackbarProvider
      dense
      maxSnack={4}
      className={clsx({
        [classes.root]: true,
        [classes.default]: true,
      })}
      style={{ pointerEvents: 'all' }}
      iconVariant={{
        success: iconMap.success,
        error: iconMap.error,
        warning: iconMap.warning,
        info: iconMap.info,
        default: iconMap.default,
      }}
      classes={{
        containerRoot: classes.collapseWrapper,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      <Component {...props} />
    </SnackbarProvider>
  );
};

export default withSnackbarProvider;
