import ChallengesIconSvg from './challenges-icon.svg';
import LabsIconSvg from './labs-icon.svg';
import RangeIconSvg from './range-icon.svg';
import HourglassSvg from './hourglass.svg';

export default {
  ChallengesIconSvg,
  LabsIconSvg,
  RangeIconSvg,
  HourglassSvg,
};
