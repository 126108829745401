import { Storage } from 'aws-amplify';

export const createImageFileFromUrl = async (imageUrl, fileName) => {
  // Fetch the image data from the URL
  const response = await fetch(imageUrl);
  const imageData = await response.blob();

  // Create a File object from the Blob
  const file = new File([imageData], fileName, {
    type: response.headers.get('content-type'),
  });

  return file;
};

export const getPreSignedUrl = async (key) => {
  const url = await Storage.get(key, { level: 'public' });
  return url;
};
