// eslint-disable-next-line import/no-extraneous-dependencies
import { BehaviorSubject } from 'rxjs';
import aafc from '../themes/aafc';
import blackGold from '../themes/black-gold';
import fdProLight from '../themes/fd-pro-light';
import fdProDark from '../themes/fd-pro-dark';
import makeTheme from '../themes/makeTheme';

const THEME_NAME = 'THEME_NAME';
const THEMES = {
  aafc: makeTheme(aafc),
  black: makeTheme(blackGold),
  light: makeTheme(fdProLight),
  dark: makeTheme(fdProDark),
};
export const DEFAULT_THEME = THEMES.light;

export const themeSubject = new BehaviorSubject(
  THEMES[localStorage.getItem(THEME_NAME)] || DEFAULT_THEME,
);

export const setTheme = (newThemeName) => {
  const matchingTheme = THEMES[newThemeName] || DEFAULT_THEME;
  const themeName = { matchingTheme };
  themeSubject.next(matchingTheme);
  localStorage.setItem(THEME_NAME, themeName);
  // set theme background color to base elements on the page (outside react app)
  const themeBackgroundColor =
    matchingTheme?.palette?.background?.default || 'transparent';
  document.querySelector('.main-content').style.backgroundColor =
    themeBackgroundColor;
  const headers = document.getElementsByTagName('header');
  if (headers.length > 0) {
    headers[0].style.backgroundColor = themeBackgroundColor;
  }
};
