import { PERMISSIONS_AFFILIATED, MANAGERIAL_PERMISSIONS } from '../constants';

const normalizePermissionFormat = (input) =>
  input.toUpperCase().replace(/-/g, '_');

const titleCaseToSnakeCase = (str) => {
  return str?.replace(/\s+/g, '_')?.toUpperCase();
};

const hasPermission = (userPermissions = [], requiredPermissions = []) => {
  const normalizedUserPermissions = userPermissions?.map(
    normalizePermissionFormat,
  );
  const normalizedRequiredPermissions = requiredPermissions?.map(
    normalizePermissionFormat,
  );

  return (
    normalizedUserPermissions?.some((permission) =>
      normalizedRequiredPermissions.includes(permission),
    ) || false
  );
};

const hasManagerialPermissions = (userPermissions = []) =>
  userPermissions
    ?.map(normalizePermissionFormat)
    ?.some((value) => MANAGERIAL_PERMISSIONS.includes(value));

const canManageUsers = (userPermissions = []) =>
  hasPermission(userPermissions, [
    PERMISSIONS_AFFILIATED.MANAGE_USERS,
    'MANAGE_ADMINS',
    'MANAGE_MANAGERS',
    'MANAGE_PARTICIPANTS',
    'VIEW_USERS',
  ]);

const canViewTemplateLibrary = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.FD_ADMIN]);

const canManageTags = (userPermissions = [], orgPricingTier) =>
  (orgPricingTier !== 'STARTER' &&
    hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.MANAGE_TAGS])) ||
  false;

const canViewDashboards = (userPermissions = [], orgPricingTier) =>
  orgPricingTier !== 'STARTER' &&
  hasPermission(userPermissions, [
    PERMISSIONS_AFFILIATED.FD_ADMIN,
    'MANAGE_TRAINING',
    PERMISSIONS_AFFILIATED.VIEW_INSIGHTS,
  ]);

const canManageContent = (userPermissions = []) =>
  hasPermission(userPermissions, [
    PERMISSIONS_AFFILIATED.CREATE,
    PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
  ]);

const canManageEvents = (userPermissions = []) =>
  hasPermission(userPermissions, [
    PERMISSIONS_AFFILIATED.MANAGE_EVENTS,
    PERMISSIONS_AFFILIATED.MANAGE_TRAINING,
  ]);

const canManageGroups = (userPermissions = [], orgPricingTier) =>
  (orgPricingTier !== 'STARTER' &&
    hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.MANAGE_GROUPS])) ||
  false;

const canCreate = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.CREATE]);

const canManageSquads = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.MANAGE_SQUADS]);

const canViewInsights = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.VIEW_INSIGHTS]);

const canReviewContent = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.REVIEW_CONTENT]);

const isFdAdmin = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.FD_ADMIN]);

const isOrgAdmin = (userPermissions = []) =>
  hasPermission(userPermissions, [PERMISSIONS_AFFILIATED.ORG_ADMIN]);

const isAccountOwner = (row) =>
  isOrgAdmin(row?.permissions?.map((r) => titleCaseToSnakeCase(r)));

const Authorization = {
  hasPermission,
  hasManagerialPermissions,
  canCreate,
  canManageUsers,
  canViewTemplateLibrary,
  canManageTags,
  canViewDashboards,
  canManageContent,
  canManageGroups,
  canManageEvents,
  canManageSquads,
  canViewInsights,
  canReviewContent,
  isFdAdmin,
  isOrgAdmin,
  isAccountOwner,
};

export default Authorization;
