import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import FdSkeleton from '../FdSkeleton';
import FdTypography from '../FdTypography';
import FdTooltip from '../FdTooltip';
import { DIFFICULTY_OPTIONS, SPECIALTY_SORT } from '../../constants';

const RowHeader = ({ flip, children }) => (
  <Box
    width="50px"
    height="24px"
    className={`flex items-center p-2 ${
      flip ? 'justify-end' : 'justify-start'
    }`}
  >
    {children}
  </Box>
);

RowHeader.propTypes = {
  flip: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const ColumnHeaders = ({ levels, flip }) => {
  return (
    <Box
      className={`flex items-center justify-center gap-x-1 ${
        flip ? 'flex-row' : 'flex-row-reverse'
      }`}
    >
      <RowHeader />
      <Box className="flex gap-x-1">
        {levels?.map((l) => (
          <FdTooltip title={DIFFICULTY_OPTIONS[l - 1]}>
            <span>
              <Box key={l} className="flex items-center justify-center h-6 w-6">
                <FdTypography variant="captiontext2">{l}</FdTypography>
              </Box>
            </span>
          </FdTooltip>
        ))}
      </Box>
    </Box>
  );
};

ColumnHeaders.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flip: PropTypes.bool.isRequired,
};

const Columns = ({ levels, id, data }) => {
  return (
    <Box className="flex gap-x-1 mb-1">
      {levels?.map((l) => {
        const cellElement = data?.find((d) => d?.yAlias === id && d?.x === l);
        const content = (
          <span>
            <Box
              key={l}
              className={`flex items-center justify-center h-6 w-6 border ${
                cellElement?.yBorderStyle
                  ? cellElement?.yBorderStyle
                  : 'border-solid'
              }`}
              style={{
                borderColor: cellElement?.yBorderColor,
                fontSize: '8px',
                backgroundColor: cellElement?.yBackgroundColor,
              }}
            >
              {cellElement?.yValue === 0 ? '' : cellElement?.yValue}
            </Box>
          </span>
        );

        return cellElement?.yToolTip ? (
          <FdTooltip title={cellElement?.yToolTip}>{content}</FdTooltip>
        ) : (
          content
        );
      })}
    </Box>
  );
};

Columns.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Row = ({ id, idTooltip, flip, children, rowHeaders }) => {
  const headerContent = rowHeaders?.find((rh) => rh?.id === id)?.headerContent;

  return (
    <Box
      className={`flex justify-center gap-x-1 ${
        flip ? 'flex-row' : 'flex-row-reverse'
      }`}
    >
      <RowHeader flip={flip}>
        <Box
          className={`flex items-center justify-center ${
            flip ? 'flex-row' : 'flex-row-reverse'
          }`}
        >
          <Box
            className="flex items-center justify-center"
            width="20px"
            height="24px"
          >
            {headerContent}
          </Box>
          <Box width="25px" className="flex items-center justify-center">
            <FdTooltip title={idTooltip}>
              <span>
                <FdTypography variant="captiontext2">{id}</FdTypography>
              </span>
            </FdTooltip>
          </Box>
        </Box>
      </RowHeader>
      {children}
    </Box>
  );
};

Row.defaultProps = {
  rowHeaders: [],
  idTooltip: '',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  flip: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  rowHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  idTooltip: PropTypes.string,
};

const FdCortex = ({ specialties, loading, legends, rowHeaders }) => {
  const theme = useTheme();
  const isDarkTheme = theme.palette.type === 'dark';

  return (
    <FdSkeleton loading={loading} height={550} width={600}>
      <Box
        id="cyber-brain"
        width="550px"
        className="relative grid grid-cols-2 gap-px gap-y-2"
      >
        <Box
          className="absolute top-6 bottom-1 inset-y-0 left-1/2 transform -translate-x-1/2 border-l border-dashed"
          style={{
            borderColor: isDarkTheme
              ? 'rgba(64, 65, 123, 1)'
              : 'rgba(227, 230, 236, 1)',
          }}
        />
        {specialties?.map((specialty) => {
          return (
            <Box
              className={`flex  ${
                specialty.flip ? 'flex-row' : 'flex-row-reverse'
              }`}
            >
              <Box
                className="flex items-center justify-center py-1 px-2 rounded self-center"
                style={{
                  backgroundColor: isDarkTheme
                    ? 'rgba(189, 189, 189, 1)'
                    : 'rgba(227, 230, 236, 1)',
                  transform: specialty.flip
                    ? 'rotate(270deg)'
                    : 'rotate(90deg)',
                  height: '24px',
                  width: '75px',
                }}
              >
                <FdTypography
                  variant="captiontext2"
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 600 }}
                >
                  {specialty.specialtyName}
                </FdTypography>
              </Box>
              <Box>
                {SPECIALTY_SORT[specialty.specialtyName]?.showHeader && (
                  <ColumnHeaders
                    levels={specialty?.levels}
                    flip={specialty.flip}
                  />
                )}
                <Box>
                  {specialty?.dataSorted?.map((d) => (
                    <>
                      <Row
                        id={d?.id}
                        tooltip={d?.data[0]?.yLabelName}
                        flip={specialty.flip}
                        rowHeaders={rowHeaders}
                        idTooltip={`${
                          specialty?.items?.find((i) => i?.alias === d?.id)
                            ?.name
                        } (${d?.id})`}
                      >
                        <Columns
                          levels={specialty.levels}
                          id={d?.id}
                          data={d?.data}
                        />
                      </Row>
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {legends}
    </FdSkeleton>
  );
};

FdCortex.defaultProps = {
  loading: false,
  rowHeaders: [],
  legends: null,
};

FdCortex.propTypes = {
  loading: PropTypes.bool,
  specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legends: PropTypes.node,
  rowHeaders: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FdCortex;
