import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncEffect from 'use-async-effect';
import { Box } from '@material-ui/core';
import FdSkeleton from '../FdSkeleton';
import { getAvatarColor, getAvatarName } from '../../shared';
import FdIconsV5 from '../FdIconsV5';
import { getPreSignedUrl } from '../../shared/image';

const { Person } = FdIconsV5;

const TeamAvatar = ({ size, team, anonymous }) => {
  const [imageUrl, setImageUrl] = useState(undefined);
  const isLargeSize = size === 'large';
  const dimension = isLargeSize ? '84px' : '16px';

  useAsyncEffect(async () => {
    const fileUrlS3 = await getPreSignedUrl(`/team-avatars/${team?.key}`);
    setImageUrl(fileUrlS3);
  });
  const NoImageAvatar = () => {
    const name = getAvatarName(team?.name);
    const avatarName = name === '-' ? '' : name;
    const avatarColor =
      name === '-' ? 'rgba(223, 223, 223, 1)' : getAvatarColor(team?.name);
    return (
      <Box
        height={dimension}
        width={dimension}
        className="relative inline-flex items-center justify-center rounded-full overflow-hidden text-white"
        style={{
          backgroundColor: anonymous ? 'lightgrey' : avatarColor,
          fontSize: 8,
          lineHeight: 1,
          fontWeight: 500,
          fontFamily: 'Montserrat',
        }}
      >
        <span>{anonymous ? <Person /> : avatarName}</span>
      </Box>
    );
  };

  if (anonymous) {
    <NoImageAvatar />;
  }

  return team?.key ? (
    <FdSkeleton
      loading={!imageUrl}
      variant="circle"
      height={dimension}
      width={dimension}
    >
      <img
        src={imageUrl}
        alt="avatar"
        style={{ width: dimension, height: dimension }}
        className="rounded-full object-cover"
      />
    </FdSkeleton>
  ) : (
    <NoImageAvatar />
  );
};

TeamAvatar.defaultProps = {
  anonymous: false,
};

TeamAvatar.propTypes = {
  team: PropTypes.objectOf(
    PropTypes.shape({
      imageKey: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  size: PropTypes.oneOf(['large', 'small']).isRequired,
  anonymous: PropTypes.bool,
};

export default TeamAvatar;
