export const ColorPalette = {
  yellow01: '#CBDB2A',
  yellow02: '#D4E154',
  lightYellow00: '#FFF3E0',
  lightBlue00: '#42A5F5', // colors.blue[400]
  blue01: '#A5BADC',
  blue02: '#538EB9',
  blue03: '#00628E',
  blue04: '#42a5f5',
  blue05: '#2196f3',
  white01: '#FFF',
  black00: '#000000',
  black01: '#030119',
  black02: '#03172D',
  black03: '#020019',
  black04: 'rgba(0, 0, 0, 0.24)',
  purple01: '#B388FF',
  purple02: '#311B92',
  purple03: '#6200EA',
  lightBlue01: '#77A2DE',
  lightBlue02: '#E3F2FD',
  lightGrey00: '#EFEFEF',
  grey01: '#777777',
  grey02: '#757575',
  grey03: '#E0E0E0',
  grey04: '#BDBDBD',
  grey05: 'rgba(255, 255, 255, 0.24)',
  grey06: 'rgba(97, 97, 97, 0.9)',
  grey07: '#717171',
  grey08: '#FAFAFA',
  grey09: '#212121',
  grey10: '#DADADA',
  grey11: '#F7F7F7',
  grey12: '#F5F5F5',
  alert: '#F44336',
  green01: '#8BC34A',
  green02: '#4caf50',
  lightGreen00: '#E8F5E9',
  purple04: '#AB47BC',
  red00: '#f44336', // error
  red01: '#D32F2F',
  lightRed00: '#FFEBEE',
  orange00: '#ff9800',
};

export const avatarColors = {
  avatarRed: '#C62828',
  avatarPink: '#F06292',
  avatarPurple: '#AB47BC',
  avatarDeepPurple: '#512DA8',
  avatarIndigo: '#5C6BC0',
  avatarBlue: '#1976D2',
  avatarLightBlue: '#81D4FA',
  avatarCyan: '#00BCD4',
  avatarTeal: '#00897B',
  avatarGreen: '#2E7D32',
  avatarLightGreen: '#8BC34A',
  avatarLime: '#D4E157',
  avatarYellow: '#FDD835',
  avatarAmber: '#FFB300',
  avatarOrange: '#F57C00',
  avatarDeepOrange: '#FF5722',
  avatarBrown: '#5D4037',
};

export default ColorPalette;
