import { BehaviorSubject } from 'rxjs';
import TokenState from '../shared/TokenStateEnum';

export const authStatusSubject = new BehaviorSubject(TokenState.DETECTING);

export const setAuthStatus = (tokenState) => {
  if (Object.values(TokenState).includes(tokenState)) {
    authStatusSubject.next(tokenState);
  } else {
    throw new Error('Invalid token state');
  }
};

export const tokenSubject = new BehaviorSubject(null);

export const setToken = (token) => {
  tokenSubject.next(token);
};
