import { useState } from 'react';
// Similar to useState hook except that if the initial value changes
// it will update the state if it hasn't been changed by calling setState
const useLazyState = (value) => {
  const [state, setState] = useState(value);
  const [modified, setModified] = useState(false);
  const output = modified ? state : value;
  const externalSetState = (newState) => {
    if (modified) {
      setState(newState);
    } else {
      setModified(true);
      // Sets state to value first in-case the new state is a state transition
      // function
      setState(value);
      setState(newState);
    }
  };

  return [output, externalSetState];
};
export default useLazyState;
