import { QueryClient } from '@tanstack/react-query';

const ReactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      retry: false,
    },
  },
});

export default ReactQueryClient;
