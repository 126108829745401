import useInitializeAAFCStore from './useInitializeAAFCStore';
import useInitializeAffiliatedStore from './useInitializeAffiliatedStore';
import useInitializeDefaultStore from './useInitializeDefaultStore';
import useInitializeProductsStore from './useInitializeProductsStore';

const useInitializeGlobalStore = () => {
  useInitializeDefaultStore();
  useInitializeAffiliatedStore();
  useInitializeAAFCStore();
  useInitializeProductsStore();
};

export default useInitializeGlobalStore;
