import { toArray } from 'react-emoji-render';

export const getMentions = (inputValue, cursorPosition) => {
  const textBeforeCursor = inputValue.substring(0, cursorPosition);
  const linesBeforeCursor = textBeforeCursor.split('\n');

  // Check if the current line contains '@' preceded by whitespace or at the beginning of a line,
  // and followed by a word character (letters, digits, or underscores),
  // but not followed by any other characters
  const currentLine = linesBeforeCursor[linesBeforeCursor.length - 1];
  const mentionRegex = /(?:^|\s)@(?!\\\[)(\w+|$)/;

  const match = currentLine.match(mentionRegex);
  if (match) {
    const mentionWord = match[1];
    const nextCharIndex = cursorPosition;
    const nextChar = inputValue.charAt(nextCharIndex);
    const isNextCharWord = /\w/.test(nextChar);

    if (!isNextCharWord) {
      return { mentions: [mentionWord] };
    }
  }

  return { mentions: [] };
};

export const parseEmojis = (value) => {
  const emojisArray = toArray(value);

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current) => {
    if (typeof current === 'string') {
      return previous + current;
    }
    return previous + current.props.children;
  }, '');

  return newValue;
};

export const getAllMentions = (text) => {
  const regex = /@\[([^\]]+)\]/g;
  const matches = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text)) !== null) {
    matches.push(`@${match[1]}`);
  }

  return matches;
};

// Regular expression to match hyperlinks starting with http:// or https:// or www.
export const HYPERLINK_REGEX = /\b(?:https?:\/\/|www\.)\S+/gi;

export const getAllHyperlinks = (text) => {
  const matches = text.match(HYPERLINK_REGEX);
  return matches || [];
};

export const removeGuardingBraces = (text) => {
  return text.replace(/@\[([^\]]+)\]/g, '@$1');
};

export const scrollToAnchor = (divId) => {
  let totalTimeElapsed = 0;
  const maxTotalTime = 5000;

  const intervalId = setInterval(() => {
    const element = document.getElementById(divId);
    if (element) {
      clearInterval(intervalId);
      element.scrollIntoView({ block: 'end' });
    }
    totalTimeElapsed += 100;

    if (totalTimeElapsed >= maxTotalTime) {
      clearInterval(intervalId);
    }
  }, 100);
};

export const replaceAtSymbolInSentence = (
  sentence,
  replacementText,
  matchingText,
) => {
  // Escape special characters in matchingText
  const escapedMatchingText = matchingText.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&',
  );
  // Create regular expression to match matchingText, ensuring it's not followed by '['
  const regex = new RegExp(
    `@(?![\\[])${escapedMatchingText.substring(1)}`,
    'g',
  );

  // Replace all occurrences of matchingText with replacementText
  const replacedSentence = sentence.replace(regex, replacementText);
  return replacedSentence;
};
