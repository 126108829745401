import React from 'react';
import PropTypes from 'prop-types';

const FdLogo = ({ isIcon }) => {
  return isIcon ? (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.4868" cy="16.2837" r="16" fill="#3333FF" />
      <path
        d="M9.96582 9.88831H22.3712V13.8321H9.96582V9.88831Z"
        fill="white"
      />
      <path
        d="M9.96582 14.6848H20.2506C21.7732 14.6848 23.0074 15.9256 23.0074 17.4562V20.0143C23.0074 21.486 21.8206 22.6791 20.3567 22.6791H9.96582V14.6848Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="217"
      height="166"
      viewBox="0 0 217 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.432617" width="216.136" height="165.653" />
      <path
        d="M64.9639 45.1219H144.407V70.831H64.9639V45.1219Z"
        fill="#3333FF"
      />
      <path
        d="M64.9639 76.4051H130.843C140.605 76.4051 148.519 84.3184 148.519 94.0801V111.002C148.519 120.763 140.605 128.677 130.843 128.677H64.9639V76.4051Z"
        fill="#3333FF"
      />
    </svg>
  );
};

FdLogo.propTypes = {
  isIcon: PropTypes.bool,
};

FdLogo.defaultProps = {
  isIcon: false,
};

export default FdLogo;
