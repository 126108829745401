import Color from 'color';
import { CATEGORY_OPTIONS } from '../constants';

const getColour = (label) => {
  switch (label) {
    case 'DRAFT':
      return 'default';
    case 'SUBMITTED':
      return 'warning';
    case 'REVIEW':
      return 'action';
    case 'BLOCKED':
      return 'error';
    case 'ACCEPTED':
      return 'done';
    case 'ARCHIVED':
      return 'default';
    default:
      return 'default';
  }
};

const getColourHex = (label) => {
  switch (label) {
    case 'SUBMITTED':
      return '#FF9800';
    case 'REVIEW':
      return '#42A5F5';
    case 'BLOCKED':
      return '#F44336';
    case 'ACCEPTED':
      return '#4CAF50';
    case 'ARCHIVED':
      return '#ddd';
    default:
      return 'ddd';
  }
};

const rowFilter = (status) => (row) => {
  switch (status) {
    case 'DRAFT':
      return row.status.label === 'DRAFT';
    case 'REVIEW':
      return row.status.label === 'REVIEW';
    case 'BLOCKED':
      return row.status.label === 'BLOCKED';
    case 'SUBMITTED':
      return row.status.label === 'SUBMITTED';
    case 'APPROVED':
      return row.status.label === 'ACCEPTED';
    case 'ARCHIVED':
      return row.status.label === 'ARCHIVED';
    default:
      return row.status.label !== 'ARCHIVED';
  }
};

const getDifficultyLabel = (difficulty) => {
  switch (difficulty) {
    case 1:
      return 'Too easy';
    case 2:
      return 'Very easy';
    case 3:
      return 'Easy';
    case 4:
      return 'Not so easy';
    case 5:
      return 'Getting tricky';
    case 6:
      return 'Average';
    case 7:
      return 'Not so average';
    case 8:
      return 'Hard';
    case 9:
      return 'Very hard';
    case 10:
      return 'Almost impossible';
    default:
      return '';
  }
};

const statusChangeOptions = (status) => {
  switch (status) {
    case 'REVIEW':
      return ['REVIEW', 'BLOCKED', 'ACCEPTED'];
    case 'SUBMITTED':
      return ['SUBMITTED', 'REVIEW'];
    case 'ACCEPTED':
      return ['ACCEPTED', 'BLOCKED'];
    case 'BLOCKED':
      return ['BLOCKED'];
    default:
      return [];
  }
};

const statusEnum = (status) => {
  switch (status) {
    case 'DRAFT':
      return 'Draft';
    case 'REVIEW':
      return 'Under review';
    case 'BLOCKED':
      return 'Blocked';
    case 'SUBMITTED':
      return 'Submitted';
    case 'ACCEPTED':
      return 'Approved';
    case 'ARCHIVED':
      return 'Archived';
    case 'EDITED':
      return 'Edited';
    case 'CREATED':
      return 'Created';
    default:
      return '';
  }
};

const getInitials = (displayName) => {
  const firstName = displayName.split(' ')[0]
    ? displayName.split(' ')[0].charAt(0)
    : '';
  const lastName = (displayName.split(' ').slice(-1)[0] || '').charAt(0);
  return `${firstName}${lastName}`.toUpperCase();
};

const getCategoryDescription = (category) =>
  (category && CATEGORY_OPTIONS.find((c) => c.id === category).label) || '';

const removeWhiteSpace = (str) => {
  return str.replace(/\s/g, '');
};

const removeDashes = (str) => {
  return str.replace(/-/g, '');
};

const isValidMobile = (ph) => {
  let mobile = '';
  if (ph && ph !== '') {
    mobile = removeWhiteSpace(ph);
    mobile = removeDashes(mobile);
  }
  return ph === '' || /^\+(?:[0-9]●?){6,14}[0-9]$/.test(mobile);
};

const getIdFromText = (text, suffix = '') =>
  typeof text === 'string' &&
  text?.replace(' ', '-').toLowerCase().concat('-', suffix);

// if it's 13-15, remap label to show 13+
const reMapAgeLabel = (ageBracket) => {
  switch (ageBracket) {
    case 'Under 13':
      return 'Under 13';
    case '13 to 15':
      return '13+';
    default:
      return '16+';
  }
};

const getTextColorByBackgroundColor = (bgColor) => {
  try {
    const contrastWithBlack = Color(bgColor).contrast(Color('black'));
    const contrastWithWhite = Color(bgColor).contrast(Color('white'));
    return contrastWithBlack > contrastWithWhite ? 'black' : 'white';
  } catch (error) {
    return 'black';
  }
};

export {
  getColour,
  getTextColorByBackgroundColor,
  rowFilter,
  getDifficultyLabel,
  statusChangeOptions,
  statusEnum,
  getColourHex,
  getInitials,
  getCategoryDescription,
  removeWhiteSpace,
  removeDashes,
  isValidMobile,
  getIdFromText,
  reMapAgeLabel,
};
