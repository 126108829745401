import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, makeStyles } from '@material-ui/core';

const FONT_WEIGHT = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

const EMPHASIS = {
  disabled: 0.3,
  medium: 0.6,
  high: 0.87,
  full: 1,
};

const customVariants = [
  'button_lg',
  'button_med',
  'button_sm',
  'captiontext1',
  'captiontext2',
];

const useStyles = makeStyles((theme) => ({
  root: ({ variant, emphasis }) => ({
    ...theme.typography[variant],
    opacity: EMPHASIS[emphasis],
  }),
  primary: {
    color: theme.palette.typography.primary,
  },
  secondary: {
    color: theme.palette.typography.secondary,
  },
  fontweightController: {
    fontWeight: ({ fontWeight }) => fontWeight && FONT_WEIGHT[fontWeight],
  },
  disabled: {
    color: theme.palette.background.disabled,
  },
}));

const FdTypography = ({
  variant,
  fontWeight,
  disabled,
  emphasis,
  className,
  ...otherProps
}) => {
  const classes = useStyles({ variant, fontWeight, emphasis });
  const isCustomVariant = customVariants.includes(variant);

  return (
    <Typography
      variant={isCustomVariant ? undefined : variant}
      {...otherProps}
      classes={{
        root: classes.root,
        colorPrimary: classes.primary,
        colorSecondary: classes.secondary,
      }}
      className={clsx(
        {
          [classes.fontweightController]: fontWeight,
          [classes.disabled]: disabled,
        },
        className,
      )}
    />
  );
};

FdTypography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body',
    'body1',
    'body2',
    'caption',
    'captiontext1',
    'captiontext2',
    'button_sm',
    'button',
    'button_med',
    'button_lg',
    'overline',
    'srOnly',
    'inherit',
  ]),
  fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
  emphasis: PropTypes.oneOf(['disabled', 'medium', 'high', 'full']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

FdTypography.defaultProps = {
  variant: undefined,
  fontWeight: undefined,
  emphasis: undefined,
  disabled: undefined,
  className: undefined,
};

export default FdTypography;
