import { useState } from 'react';
import useObservable from './useObservable';
import { themeSubject } from '../subjects/theme-subject';

const useAppTheme = () => {
  const [theme, setTheme] = useState(themeSubject.getValue());

  useObservable(themeSubject, setTheme);

  return theme;
};

export default useAppTheme;
