import * as React from 'react';
import { getIn } from 'formik';
import FdTextField from '../FdTextField/FdTextField';

const fieldToTextField = ({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur
        ?? function (e) {
          fieldOnBlur(e ?? field.name);
        },
    ...field,
    ...props,
  };
};

const FdTextFieldFormik = (
  // eslint-disable-next-line react/jsx-props-no-spreading
  { ...props },
) => (
  <FdTextField
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...fieldToTextField(props)}
  />
);

export default FdTextFieldFormik;
