import React from 'react';
import { AppBar, Toolbar, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './style';

const TopNav = ({ leftComponents, rightComponents }) => {
  const classes = useStyles();
  return (
    <Box>
      <AppBar>
        <Toolbar className={[classes.floatRight, classes.toolbar].join(' ')}>
          {/* Left side stuff */}
          <Box display="flex" alignItems="center" className={classes.itemBox}>
            {leftComponents?.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </Box>
          {/*  Right side stuff */}
          <Box display="flex" alignItems="center" className={classes.itemBox}>
            {rightComponents?.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

TopNav.propTypes = {
  /** set of elements to be aligned on the left side of the navbar */
  leftComponents: PropTypes.arrayOf(PropTypes.any),
  /** set of elements to be aligned on the right side of the navbar */
  rightComponents: PropTypes.arrayOf(PropTypes.any),
};

TopNav.defaultProps = {
  leftComponents: [],
  rightComponents: [],
};

export default TopNav;
