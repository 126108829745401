/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useAppTheme } from '../hooks';

const withThemeV5ProviderSubscribe = (Component) => (props) => {
  const theme = useAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withThemeV5ProviderSubscribe;
