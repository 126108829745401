export const CATEGORY_OPTIONS = [
  { id: 'CRYPTOGRAPHY', label: 'Cryptography' },
  { id: 'SCRIPTING', label: 'Scripting' },
  { id: 'EXPLOITATION', label: 'Exploitation' },
  { id: 'REVENG', label: 'Reverse Engineering' },
  { id: 'OSINT', label: 'OSINT' },
  { id: 'STEGANOGRAPHY', label: 'Steganography' },
  { id: 'BOOTTOROOT', label: 'boot2root' },
  { id: 'DFIR', label: 'DFIR' },
  { id: 'MISC', label: 'Miscellaneous' },
  { id: 'HARDWARE', label: 'Hardware' },
  { id: 'WIRELESS', label: 'Wireless' },
  { id: 'IOT', label: 'IoT' },
  { id: 'ANALYSIS', label: 'Analysis' },
  { id: 'PHYSICAL', label: 'Physical' },
  { id: 'TRIVIA', label: 'Trivia' },
  { id: 'ICSSCADA', label: 'ICS/SCADA' },
  { id: 'FORENSICS', label: 'Forensics' },
  { id: 'PWN', label: 'Pawn' },
  { id: 'ANALYST', label: 'Analyst' },
  { id: 'BADGE', label: 'Badge' },
];

export const DIFFICULTY_OPTIONS = [
  'Novice',
  'Advanced Beginner',
  'Competent',
  'Proficient',
  'Expert',
];

export const HELP_HERO_TOUR_ID = '4PjETxFEbC3';

export const PRIVACY_POLICY_URL =
  'https://au.intercom.help/fifth-domain/en/articles/28049-privacy-policy';
export const TOS_URL =
  'https://au.intercom.help/fifth-domain/en/articles/1763-terms-and-conditions-of-use';
export const IP_POLICY_URL =
  'https://au.intercom.help/fifth-domain/en/articles/1769-intellectual-property-policy-effective-9-august-2021';
export const STANDARDS_OF_USE_URL =
  'https://au.intercom.help/fifth-domain/en/articles/1765-standards-of-use-policy-effective-9-august-2021';
export const WHY_DO_WE_ASK_URL =
  'https://au.intercom.help/fifth-domain/en/articles/1771-why-does-fifth-domain-collect-my-personal-information';
export const CREATE_TICKET_ASSESSOR_URL =
  'https://fifthdomain.atlassian.net/servicedesk/customer/portal/1/group/1/create/49';
export const CREATE_TICKET_COMPETITIONS_URL =
  'https://fifthdomain.atlassian.net/servicedesk/customer/portal/1/group/1/create/43';
export const HELPDESK_EMAIL = 'helpdesk@fifthdomain.pro';
export const SUPPORT_EMAIL = 'support@fifthdomain.pro';
export const TAG_MANAGEMENT_URL =
  'https://au.intercom.help/fifth-domain/en/articles/1782-tag-management-guidelines';
export const USER_PERMISSIONS_EXPLAINER =
  'https://au.intercom.help/fifth-domain/en/articles/1778-user-permissions-explainer';
export const MANAGER_ROLES_PERMISSIONS_GUIDE =
  'https://au.intercom.help/fifth-domain/en/articles/2141-manager-roles-permissions-guide';
export const WORK_ROLE_OVERLAY_GUIDE =
  'https://au.intercom.help/fifth-domain/en/articles/33237-overlaying-work-role-skills-onto-a-user-s-profile';

export const WHITE_LABELLED_ORGS = ['asd', 'qut', 'csa'];

// colors common to all themes -from Material-UI
// all these colors are lighter shade and its taken from Alert component
export const COMMON_VARIANT_COLORS = {
  default: 'rgb(255, 255, 255)',
  info: 'rgb(229, 246, 253)',
  warning: 'rgb(255, 244, 229)',
  success: 'rgb(237, 247, 237)',
  error: 'rgb(253, 237, 237)',
};

export const PERMISSIONS_AFFILIATED = Object.freeze({
  PARTICIPATE: 'PARTICIPATE',
  MANAGE_PROFILE: 'MANAGE_PROFILE',
  CREATE: 'CREATE',
  MANAGE_EVENTS: 'MANAGE_EVENTS',
  MANAGE_TRAINING: 'MANAGE_EVENTS', // for support for previous users in the system
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  MANAGE_USERS: 'MANAGE_USERS',
  MANAGE_ADMINS: 'MANAGE_USERS',
  MANAGE_MANAGERS: 'MANAGE_USERS',
  MANAGE_PARTICIPANTS: 'MANAGE_USERS',
  MANAGE_CONTENT: 'MANAGE_CONTENT',
  MANAGE_TAGS: 'MANAGE_TAGS',
  MANAGE_SQUADS: 'MANAGE_SQUADS',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  VIEW_BILLING: 'VIEW_BILLING',
  REVIEW_CONTENT: 'REVIEW_CONTENT',
  FD_ADMIN: 'FD_ADMIN',
  ORG_ADMIN: 'ORG_ADMIN',
});

export const PERMISSIONS_NON_AFFILIATED = Object.freeze({
  PARTICIPATE: 'PARTICIPATE',
  MANAGE_PROFILE: 'MANAGE_PROFILE',
});

export const PERMISSIONS_AFFILIATED_DISPLAY = Object.keys(
  PERMISSIONS_AFFILIATED,
)?.filter(
  (key) =>
    ![
      'PARTICIPATE',
      'MANAGE_PROFILE',
      'FD_ADMIN',
      'MANAGE_ADMINS',
      'MANAGE_PARTICIPANTS',
      'MANAGE_MANAGERS',
      'MANAGE_TRAINING',
      'ORG_ADMIN',
      // hiding as it is not used
      'VIEW_BILLING',
    ].includes(key),
);

export const MANAGERIAL_PERMISSIONS = [
  PERMISSIONS_AFFILIATED.FD_ADMIN,
  'MANAGE_TRAINING',
  PERMISSIONS_AFFILIATED.MANAGE_EVENTS,
  PERMISSIONS_AFFILIATED.CREATE,
  'MANAGE_PARTICIPANTS',
  'MANAGE_MANAGERS',
  PERMISSIONS_AFFILIATED.MANAGE_GROUPS,
  'MANAGE_ADMINS',
  PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
  PERMISSIONS_AFFILIATED.MANAGE_TAGS,
  PERMISSIONS_AFFILIATED.MANAGE_USERS,
  PERMISSIONS_AFFILIATED.VIEW_INSIGHTS,
  PERMISSIONS_AFFILIATED.VIEW_BILLING,
  PERMISSIONS_AFFILIATED.REVIEW_CONTENT,
  PERMISSIONS_AFFILIATED.MANAGE_SQUADS,
];

export const SPECIALTY_SORT = {
  Intelligence: { order: 1, showHeader: true },
  Penetration: { order: 2, showHeader: true },
  Protection: { order: 3 },
  Detection: { order: 4 },
  Engineering: { order: 5 },
  Investigation: { order: 6 },
};

export const orgDetailsPermissions = [
  PERMISSIONS_AFFILIATED.MANAGE_EVENTS,
  PERMISSIONS_AFFILIATED.MANAGE_GROUPS,
  PERMISSIONS_AFFILIATED.MANAGE_USERS,
  PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
  PERMISSIONS_AFFILIATED.MANAGE_TAGS,
  PERMISSIONS_AFFILIATED.MANAGE_SQUADS,
];
