const typography = {
  fontweight_regular: 400,
  fontweight_medium: 500,
  fontweight_bold: 700,
  h1: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.05,
    fontSize: '2.5rem',
  },
  h2: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.25,
    fontSize: '2rem',
  },
  h3: {
    fontWeight: 500,
    letterSpacing: '0em',
    lineHeight: 1.5,
    fontSize: '1.5rem',
  },
  h4: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.4,
    fontSize: '1.25rem',
  },
  subtitle1: {
    fontWeight: 500,
    letterSpacing: '0em',
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  subtitle2: {
    fontWeight: 500,
    letterSpacing: '0em',
    lineHeight: 1.428571429,
    fontSize: '0.875rem',
  },
  body1: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  body2: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.428571429,
    fontSize: '0.875rem',
  },
  button_lg: {
    fontWeight: 500,
    letterSpacing: '0.025em',
    lineHeight: 1.5,
    fontSize: '1rem',
    textTransform: 'uppercase',
  },
  button_med: {
    fontWeight: 500,
    letterSpacing: '0.025em',
    lineHeight: 1.142857143,
    fontSize: '0.875rem',
    textTransform: 'uppercase',
  },
  button_sm: {
    fontWeight: 500,
    letterSpacing: '0.025em',
    lineHeight: 1.3333333333,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
  },
  captiontext1: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.3333333333,
    fontSize: '0.75rem',
  },
  captiontext2: {
    fontWeight: 400,
    letterSpacing: '0em',
    lineHeight: 1.4,
    fontSize: '0.625rem',
  },
};

export default typography;
