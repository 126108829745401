import jwtDecode from 'jwt-decode';
import { tokenSubject } from '../subjects/auth-subject';

export default function userHasAccess(scope) {
  const decoded = jwtDecode(tokenSubject.getValue());
  const scopes = decoded.scope.split(' ');
  if (scopes.includes('fifthdomain')) {
    return true;
  }
  return decoded.scope.split(' ').includes(scope);
}
