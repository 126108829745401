import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tabs, Tab, Badge, Box } from '@material-ui/core';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles((theme) => ({
  activeTabs: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    bottom: '17px',
  },
  tab: {
    '& [class*="MuiTabs-flexContainer"]': {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    '& [class*="MuiTab-root"]': {
      fontWeight: '400',
      minHeight: '39px',
      minWidth: 'fit-content',
      padding: '9px 16px',
      textTransform: 'inherit',
    },
    '& [class*="MuiTab-wrapper"]': {
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
    },
    '& [class*="MuiTab-textColorPrimary"][class*="Mui-disabled"]': {
      color: `${theme.palette.background.disabled} !important`,
    },
    '& [class*="MuiTab-textColorPrimary"][class*="Mui-selected"] p': {
      fontWeight: '600',
      fontSize: 14,
      color: `${theme.palette.primary.main} !important`,
    },
    '& [class*="MuiTab-textColorPrimary"][class*="Mui-selected"]': {
      fontWeight: '600',
      fontSize: 14,
      color: `${theme.palette.primary.main} !important`,
    },
    '& [class*="MuiButtonBase-root"]': {
      borderBottom: '1px solid',
      overflow: 'inherit',
      color: `${theme.palette.typography.secondary} !important`,
    },
    '& [class*="MuiBadge-anchorOriginTopRightRectangle"]': {
      top: '-6px',
      right: '-12px',
    },
  },
  badge: {
    color: ColorPalette.red00,
    '& [class*="MuiBadge-colorSecondary"]': {
      backgroundColor: ColorPalette.red00,
    },
  },
}));
const FdTab = ({
  label,
  index,
  custIndex,
  setCustIndex,
  tabLinkComponent,
  onTabChange,
  disableTabChange,
  onBeforeTabChange,
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(
    Math.abs(parseInt(index, 10)) <= label.length ? index : 0,
  );
  const handleChange = (event, newValue) => {
    onBeforeTabChange?.(newValue);
    if (disableTabChange) {
      return;
    }
    setValue(newValue);
    if (setCustIndex) {
      setCustIndex(undefined);
    }
    onTabChange?.(newValue);
  };
  useEffect(() => {
    // set custIndex when no: of tabs are within the tab range
    if (custIndex && Math.abs(parseInt(custIndex, 10)) <= label.length) {
      setValue(custIndex);
    }
  }, [custIndex, label.length]);

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        classes={{
          indicator: classes.activeTabs,
        }}
        className={classes.tab}
        {...props}
      >
        {label.map(
          ({ badge, label: labelText, customLabel, disabled, tabRoute }, i) => {
            // set routeProps if necessary - sets router link to each tab link
            const routeProps = {
              ...(tabRoute &&
                tabLinkComponent && {
                  component: tabLinkComponent,
                  to: tabRoute,
                }),
            };
            return badge ? (
              <Tab
                key={i}
                label={
                  <Badge
                    className={classes.badge}
                    color="secondary"
                    badgeContent={badge}
                  >
                    {labelText}
                  </Badge>
                }
                disabled={disabled}
                className={classes.box}
                {...routeProps}
              />
            ) : (
              <Tab
                key={i}
                label={customLabel || labelText}
                disabled={disabled}
                className={classes.box}
                {...routeProps}
              />
            );
          },
        )}
      </Tabs>
      {label?.[value]?.data && <Box mx={0}>{label[value]?.data}</Box>}
    </Box>
  );
};
FdTab.propTypes = {
  label: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      badge: PropTypes.number,
      data: PropTypes.func.isRequired,
      tabRoute: PropTypes.string,
    }),
  ),
  index: PropTypes.number,
  custIndex: PropTypes.number,
  setCustIndex: PropTypes.func,
  // either Button (default) or Link from react-router-dom
  tabLinkComponent: PropTypes.shape({}),
  onTabChange: PropTypes.func,
  disableTabChange: PropTypes.bool,
  onBeforeTabChange: PropTypes.func,
};
FdTab.defaultProps = {
  label: PropTypes.arrayOf(
    PropTypes.shape({
      label: null,
      disabled: false,
      badge: 0,
      data: () => {},
      tabRoute: '',
    }),
  ),
  index: 0,
  custIndex: undefined,
  setCustIndex: () => {},
  tabLinkComponent: undefined,
  onTabChange: undefined,
  disableTabChange: false,
  onBeforeTabChange: undefined,
};
export default FdTab;
