import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, InputLabel, InputAdornment } from '@material-ui/core';
import useStyles from './styles';

const FdTextField = ({
  id,
  label,
  variant,
  startAdornment,
  endAdornment,
  fullWidth,
  required,
  disabled,
  width,
  ...props
}) => {
  const classes = useStyles();
  const customWidth = width || '307px';
  return (
    <Box
      display={fullWidth ? 'flex' : 'inline-flex'}
      flexDirection="column"
      width={fullWidth ? 'auto' : customWidth}
    >
      {id && label && (
        <InputLabel
          htmlFor={id}
          data-cy={`label-for-${id}`}
          classes={{
            root: classes.labelRoot,
            disabled: classes.disabled,
          }}
          disabled={disabled}
        >
          {label}
          {!required && (
            <span
              className={classes.optionalLabel}
              style={{ opacity: disabled && '30%' }}
            >
              &nbsp;optional
            </span>
          )}
        </InputLabel>
      )}
      <TextField
        {...props}
        label=""
        disabled={disabled}
        data-cy={id || ''}
        variant={variant}
        classes={{
          root: classes.textFieldRoot,
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.formhelperspacing,
          },
        }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
            error: classes.error,
          },
        }}
      />
    </Box>
  );
};

FdTextField.propTypes = {
  /** These are the types of textfields */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  /** Id for textfield */
  id: PropTypes.string.isRequired,
  /** Label for textfield */
  label: PropTypes.string,
  /** Start adornment - can be a string or a React Element like an Icon */
  startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** End adornment - can be a string or a React Element like an Icon */
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** To grow fullwidth of the layout */
  fullWidth: PropTypes.bool,
  /** Required or not */
  required: PropTypes.bool,
  /** Disabled or not */
  disabled: PropTypes.bool,
  /** Custom width */
  width: PropTypes.string,
};

FdTextField.defaultProps = {
  variant: 'outlined',
  label: '',
  startAdornment: '',
  endAdornment: '',
  fullWidth: false,
  required: false,
  disabled: false,
  width: '',
};

export default FdTextField;
