/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useAppTheme } from '../hooks';

const withThemeProviderSubscribe = (Component) => (props) => {
  const theme = useAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withThemeProviderSubscribe;
