import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { InputLabel } from '@material-ui/core/';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '268px',
    height: '48px',
    marginTop: '-10px',
    borderRadius: '4px',
    '& [class*="MuiFormLabel-root"]': {
      padding: '5px 0  0 12px',
    },
    '& [class*="MuiInputBase-input"]': {
      padding: '7px 0 7px 10px',
    },
    '& [class*="MuiFormHelperText"]': {
      marginLeft: '0',
      width: 'max-content',
    },
  },
  labelRoot: {
    lineHeight: '1.5rem',
    fontWeight: 600,
    color:
      (theme.palette.typography && theme.palette.typography.primary) ||
      ColorPalette.black00,
    overflowWrap: 'break-word',
  },
  disabled: {
    color: theme.palette.background.disabled,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
}));

const FdDateTimePicker = ({
  value,
  onChange,
  format,
  id,
  label,
  disableToolbar,
  custIcon,
  custIconDirection,
  placeholder,
  readOnly,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.container}>
        <InputLabel
          htmlFor={id}
          id={`${id}-label`}
          shrink={false}
          classes={{
            root: classes.labelRoot,
            disabled: classes.disabled,
          }}
        >
          {label}
        </InputLabel>
        <KeyboardDateTimePicker
          disableToolbar={disableToolbar}
          variant="inline"
          format={format}
          margin="normal"
          id={id || 'date-picker-inline'}
          views={['year', 'month', 'date', 'hours', 'minutes']}
          value={value}
          onChange={onChange}
          classes={{ root: classes.root }}
          rightArrowButtonProps={{ disabled: false }}
          ampm={false}
          readOnly={readOnly}
          strictCompareDates
          keyboardIcon={custIcon}
          InputAdornmentProps={{ position: custIconDirection }}
          inputVariant="outlined"
          placeholder={placeholder}
          InputProps={{
            style: {
              fontSize: 16,
              padding: '10px',
            },
          }}
          {...props}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

FdDateTimePicker.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disableToolbar: PropTypes.bool,
  readOnly: PropTypes.bool,
  custIcon: PropTypes.node,
  custIconDirection: PropTypes.oneOf(['start', 'end']),
};

FdDateTimePicker.defaultProps = {
  format: 'dd/MM/yyyy HH:mm',
  label: '',
  id: undefined,
  placeholder: undefined,
  value: undefined,
  disableToolbar: false,
  readOnly: false,
  custIcon: <CalendarTodayIcon />,
  custIconDirection: 'end',
};

export default FdDateTimePicker;
