import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTheme } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';

const FdCircularProgressBar = ({ ...props }) => {
  const theme = useTheme();

  return (
    <CircularProgressbar
      strokeWidth={10}
      styles={buildStyles({
        strokeLinecap: 'butt',
        textSize: '16px',
        pathTransitionDuration: 0.5,
        pathColor: '#3333FF',
        textColor: '#000',
        trailColor: theme.palette.type === 'dark' ? '#131353' : '#E0E0E0',
      })}
      {...props}
    />
  );
};

export default FdCircularProgressBar;
