import { useEffect } from 'react';
import PropTypes from 'prop-types';

function FdChatbot({ userEmail, userHash }) {
  useEffect(() => {
    window.intercomSettings = {
      api_base: 'https://api-iam.au.intercom.io',
      app_id: 'mxnen818',
      email: userEmail,
      user_hash: userHash,
    };

    const intercom = window.Intercom;
    if (typeof intercom === 'function') {
      intercom('reattach_activator');
      intercom('update', window.intercomSettings);
    } else {
      window.Intercom = (...args) => {
        window.Intercom.c(args);
      };
      window.Intercom.q = [];
      window.Intercom.c = (...args) => {
        window.Intercom.q.push(args);
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://widget.intercom.io/widget/mxnen818';
      document
        .getElementsByTagName('script')[0]
        .parentNode.insertBefore(script, null);
    }
  }, [userEmail, userHash]);

  return null;
}

FdChatbot.propTypes = {
  userEmail: PropTypes.string.isRequired,
  userHash: PropTypes.string.isRequired,
};

export default FdChatbot;
