/* FdModal */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  DialogTitle,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import FdTypography from '../FdTypography';
import FdButton from '../FdButton';
import { getIdFromText } from '../../shared/utils';
import FdIconsV5 from '../FdIconsV5';

const sizeMap = {
  xs: '400',
  sm: '500',
  md: '600',
  lg: '800',
  xl: '1000',
};

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    padding: '24px',
    display: 'inline-flex',
    justifyContent: 'flex-end',
  },
  dialogActionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: 16,
    },
  },
  paper: ({ size }) => ({
    backgroundColor: theme.palette.background.menu,
    width: `${sizeMap[size]}px`,
  }),
  dialogContentRoot: {
    padding: '0 24px',
    paddingBottom: 0,
  },
  dialogTitle: {
    padding: 24,
    paddingBottom: 0,
    '& h3': {
      paddingBottom: 24,
    },
  },
  buttonRed: {
    backgroundColor: 'rgba(198, 40, 40, 1)',
  },
  buttonRedText: {
    color: 'rgba(198, 40, 40, 1)',
    border: '1px solid rgba(198, 40, 40, 1)',
    '&:hover': {
      color: 'rgba(198, 40, 40, 1)',
      border: '1px solid rgba(198, 40, 40, 1)',
    },
  },
}));

const FdModal = ({
  size,
  title,
  description,
  confirm,
  onConfirm,
  dismiss,
  onDismiss,
  actions,
  open,
  children,
  showDismiss,
  disableConfirm,
  confirmLoading,
  showConfirm,
  showConfirmInRed,
  showDismissInRed,
  showClose,
  ...props
}) => {
  const classes = useStyles({ size });
  const { Close } = FdIconsV5;
  // close on escape key
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      onDismiss();
    }
  };

  return (
    <Dialog
      {...props}
      maxWidth={false}
      open={open}
      PaperProps={{ classes: { root: classes.paper } }}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <FdTypography variant="h3">{title}</FdTypography>
        <FdTypography variant="body1" color="secondary">
          {description}
        </FdTypography>
        {showClose && (
          <IconButton
            style={{ position: 'absolute', top: '1rem', right: '1rem' }}
            onClick={onDismiss}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {children}
      </DialogContent>

      <DialogActions
        className={classes.buttonRow}
        classes={{ spacing: classes.dialogActionsSpacing }}
      >
        {actions?.length > 0 &&
          actions?.map(({ text, callback }, index) => (
            <FdButton
              key={index}
              variant="tertiary"
              size="large"
              onClick={callback}
              data-cy={getIdFromText(text, 'button')}
            >
              {text}
            </FdButton>
          ))}
        {!showClose && showDismiss && (
          <FdButton
            variant="secondary"
            size="large"
            onClick={onDismiss}
            data-cy="modal-dismiss-button"
            className={showDismissInRed && classes.buttonRedText}
          >
            {dismiss}
          </FdButton>
        )}
        {!showClose && showConfirm && (
          <FdButton
            variant="primary"
            size="large"
            onClick={onConfirm}
            disabled={disableConfirm}
            data-cy="modal-confirm-button"
            startIcon={confirmLoading && <CircularProgress size={20} />}
            className={showConfirmInRed && classes.buttonRed}
          >
            {confirm}
          </FdButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

FdModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirm: PropTypes.string,
  onConfirm: PropTypes.func,
  dismiss: PropTypes.string,
  onDismiss: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      callback: PropTypes.func,
    }),
  ),
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  showDismiss: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  size: PropTypes.string,
  confirmLoading: PropTypes.bool,
  showConfirm: PropTypes.bool,
  showConfirmInRed: PropTypes.bool,
  showDismissInRed: PropTypes.bool,
  showClose: PropTypes.bool,
};

FdModal.defaultProps = {
  title: undefined,
  description: undefined,
  confirm: undefined,
  onConfirm: () => {},
  dismiss: undefined,
  onDismiss: () => {},
  actions: [],
  children: undefined,
  showDismiss: true,
  disableConfirm: false,
  size: 'lg',
  confirmLoading: false,
  showConfirm: true,
  showConfirmInRed: false,
  showDismissInRed: false,
  showClose: false,
};

export default FdModal;
