import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: ({ size }) => {
    const rootStyle = {
      padding: '8px 16px',
      fontSize: '14px',
      minHeight: '32px',
      height: 'fit-content',
      fontWeight: theme.palette.type === 'dark' ? 400 : 600,
      lineHeight: 'normal',
      fontFamily: theme.palette.type === 'dark' ? 'Raitor' : 'Montserrat',
      letterSpacing: theme.palette.type === 'dark' ? '0.1em' : 'normal',
    };
    if (size === 'small') {
      rootStyle.padding = '0 12px';
      rootStyle.fontSize = '12px';
      rootStyle.minHeight = '28px';
    } else if (size === 'large') {
      rootStyle.fontSize = '16px';
      rootStyle.minHeight = '40px';
    }
    return rootStyle;
  },
  primaryStyles: {
    color:
      theme.palette.type === 'dark'
        ? '#000'
        : theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    '&:hover': {
      color:
        theme.palette.type === 'dark'
          ? '#fff'
          : theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      outline: 'none',
    },
    '&:disabled': {
      color: theme.palette.typography.disabled,
      backgroundColor: theme.palette.background.disabled,
      border: 'none',
    },
  },
  startIcon: {
    width: 20,
  },
  endIcon: {
    width: 20,
  },
  secondaryStyles: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'transparent',
    color: theme.palette.primary.main,
    borderRadius: '4px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: theme.palette.background.disabled,
      backgroundColor: 'transparent',
      borderColor: theme.palette.background.disabled,
    },
  },
  tertiaryStyles: {
    color: theme.palette.primary.main,
    background: 'transparent',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: theme.palette.background.disabled,
    },
  },
}));

const variantMap = {
  primary: 'contained',
  secondary: 'outlined',
  tertiary: 'text',
};

const FdButton = ({
  variant,
  size,
  disabled,
  classes: overrideClasses,
  startIcon,
  endIcon,
  ...props
}) => {
  const classes = useStyles({ size });
  return (
    <Button
      {...props}
      variant={variantMap[variant]}
      color="primary"
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      disableTouchRipple
      disableFocusRipple
      disableElevation
      data-cy={props['data-cy'] || props.id}
      classes={{
        root: classes.root,
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
        containedPrimary: classes.primaryStyles,
        outlinedPrimary: classes.secondaryStyles,
        textPrimary: classes.tertiaryStyles,
        focusVisible: classes.focus,
        ...overrideClasses,
      }}
    />
  );
};
FdButton.propTypes = {
  /** These are the types of buttons */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /** In 3 sizes */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Disabled */
  disabled: PropTypes.bool,
  /** Classes can be passed to the button to override Mui styles */
  classes: PropTypes.shape({}),
  /** Icon on left of button (optional) */
  startIcon: PropTypes.node,
  /** Icon on right of button (optional) */
  endIcon: PropTypes.node,
  'data-cy': PropTypes.string,
  id: PropTypes.string,
};

FdButton.defaultProps = {
  variant: 'primary',
  size: 'medium',
  disabled: false,
  classes: {},
  startIcon: undefined,
  endIcon: undefined,
  'data-cy': undefined,
  id: undefined,
};

export default FdButton;
