import React from 'react';

const FdLogoSmall = () => {
  return (
    <svg
      width="70"
      height="100"
      viewBox="0 0 217 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.432617" width="216.136" height="165.653" />
      <path
        d="M64.9639 45.1219H144.407V70.831H64.9639V45.1219Z"
        fill="#3333FF"
      />
      <path
        d="M64.9639 76.4051H130.843C140.605 76.4051 148.519 84.3184 148.519 94.0801V111.002C148.519 120.763 140.605 128.677 130.843 128.677H64.9639V76.4051Z"
        fill="#3333FF"
      />
    </svg>
  );
};

export default FdLogoSmall;
