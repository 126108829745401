import { makeStyles } from '@material-ui/core/styles';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    '& [class*="MuiOutlinedInput-root"][class*="Mui-focused"] [class*="MuiOutlinedInput-notchedOutline"]':
      {
        borderWidth: '1px',
      },
    '& [class*="MuiInputBase-input"]': {
      lineHeight: '1.5rem',
      paddingBottom: '3px',
      color: theme.palette.typography.primary || ColorPalette.black00,
      background: theme.palette.inputs.background,
    },
    '& [class*="MuiOutlinedInput-adornedStart"]': {
      display: 'block',
      overflow: 'hidden',
      padding: '10px',
      background:
        (theme.palette.inputs && theme.palette.inputs.background) ||
        ColorPalette.white01,
      '& > [class*="MuiInputBase-input"]': {
        background: 'none',
      },
      '& [class*="Muitext-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
        fontWeight: 500,
      },
      '& [class*="MuiSvgIcon-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
      },
    },
    '& [class*="MuiOutlinedInput-adornedEnd"]': {
      paddingRight: '0.75rem',
      background:
        (theme.palette.inputs && theme.palette.inputs.background) ||
        ColorPalette.white01,
      '& > [class*="MuiInputBase-input"]': {
        background: 'none',
      },
      '& [class*="Muitext-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
        fontWeight: 500,
      },
      '& [class*="MuiSvgIcon-root"]': {
        color:
          (theme.palette.typography && theme.palette.typography.secondary) ||
          ColorPalette.black00,
      },
    },
    '& [class*="MuiInputBase-adornedStart"] > [class*="MuiOutlinedInput-input"]':
      {
        paddingLeft: 0,
      },
    '& [class*="MuiInputBase-adornedEnd"] > [class*="MuiOutlinedInput-input"]':
      {
        paddingRight: 0,
      },
    '& [class*="MuiInputBase-multiline"]': {
      padding: '0.75rem',
      background:
        (theme.palette.inputs && theme.palette.inputs.background) ||
        ColorPalette.white01,
    },
    '& [class*="MuiInputBase-inputMultiline"]': {
      padding: 0,
      lineHeight: '1.5rem',
      minHeight: '3rem',
      background: 'none',
    },
    '& [class*="MuiFormHelperText-root"]': {
      marginLeft: 0,
      fontWeight: 400,
      color:
        (theme.palette.typography && theme.palette.typography.primary) ||
        ColorPalette.black00,
    },
    '& [class*="MuiFormHelperText-root"][class*="Mui-error"]': {
      color: ColorPalette.red00,
    },
    '& [class*="MuiFormLabel-root"][class*="Mui-disabled"]': {
      color: 'red',
    },
    '& [class*="MuiFormHelperText-root"][class*="Mui-disabled"]': {
      opacity: '30%',
    },
    '& [class*="MuiInput-underline"] > [class*="MuiInputBase-input"]': {
      padding: '6px 0 7px',
    },
  },
  chipContainer: {
    maxHeight: '130px',
    overflowY: 'scroll',
  },
  chip: {
    margin: '0 4px 4px 0 !important',
    background: '#BDBDBD',
  },
  chipInput: {
    paddingTop: '10px',
  },
  formhelperspacing: {
    marginTop: '4px',
    marginLeft: 0,
  },
  disabled: {
    color:
      (theme.palette.typography &&
        `${theme.palette.typography.disabled} !important`) ||
      ColorPalette.black00,
  },
  labelRoot: {
    lineHeight: '1.5rem',
    fontWeight: 500,
    color:
      (theme.palette.typography && theme.palette.typography.primary) ||
      ColorPalette.black00,
    overflowWrap: 'break-word',
  },
  optionalLabel: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color:
      (theme.palette.typography && theme.palette.typography.secondary) ||
      ColorPalette.black00,
  },
}));

export default useStyles;
