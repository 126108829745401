import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.sidebar,
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  floatRight: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default useStyles;
