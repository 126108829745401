// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: '2px',
  },
  tabName: {
    color: theme.palette.background.dark,
  },
  hideTab: {
    display: 'none',
  },
  tabHeader: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24)',
  },
  labelText: {
    textTransform: 'none',
  },
  flatCondensed: {
    boxShadow: 'none',
    paddingLeft: 0,
    '& [class*="MuiTab-root"]': {
      minWidth: 0,
    },
    '& [class*="MuiButtonBase-root"]': {
      borderBottom: `1px solid ${theme.palette.secondary.contrastText}`,
    },
  },
}));

export default useStyles;
