import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import colorPalette from '../../shared/colors';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '24px',
    backgroundColor: colorPalette.grey06,
    borderRadius: '8px',
    ...theme.typography.captiontext1,
  },
}));

const FdTooltip = ({ title, position, arrow, children, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      placement={position}
      arrow={arrow}
      {...otherProps}
      classes={{ tooltip: classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
};

FdTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  arrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    'bottom',
    'bottom-start',
    'bottom-end',
    'left-end',
    'left-start',
    'left',
    'right-start',
    'right-end',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

FdTooltip.defaultProps = {
  position: 'bottom',
  arrow: false,
};

export default FdTooltip;
