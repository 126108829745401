import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = (key) => (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const showToast = ({
    message,
    variant,
    anchorOrigin,
    autoHideDuration,
    persist,
    showCloseIcon,
  }) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin,
      persist: variant === 'error' ? true : persist,
      autoHideDuration: !persist && autoHideDuration,
      action: showCloseIcon && action,
    });
  };

  return {
    showToast,
  };
};

export default useToast;
