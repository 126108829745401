import ctfIcon from '../../resources/icons/challenges-icon.svg';
import rangeIcon from '../../resources/icons/range-icon.svg';
import labIcon from '../../resources/icons/labs-icon.svg';
import { ColorPalette } from '../shared/colors';
import typography from '../typography/typography';
import { fdProColors } from './fd-pro-colors';

const c = {
  background: 'rgba(245, 245, 245, 1)',
  border: 'rgba(224, 224, 224, 1)',
  paper: '#fff',
  primary: 'rgba(51, 51, 255, 1)',
  primary_dark: 'rgba(29, 29, 182, 1)',
  primary_light: 'rgba(110, 132, 255, 1)',
  text_primary: 'rgba(0, 0, 0, 0.95)',
  text_secondary: 'rgba(0, 0, 0, 0.7)',
  text_placeholder: 'rgba(0, 0, 0, 0.38)',
  text_disabled: 'rgba(0, 0, 0, 0.24)',
  hover: 'rgba(235, 242, 255, 1)',
  input_background: 'rgba(0,0,0,0.03)',
  input_background_hover: 'rgba(0, 0, 0, 0.05)',
  input_border: '#BDBDBD',
  input_border_hover: ColorPalette.grey02,
  select_border: '#424242',
  selected: 'rgba(0, 0, 0, 0.12)',
  thumb: '#424242',
  icon: 'rgba(0, 0, 0, 0.65)',
};

const theme = {};

theme.common = {
  black: '#000',
  white: '#fff',
};
theme.fdProColors = fdProColors;
theme.resources = { ctfIcon, rangeIcon, labIcon };

theme.palette = {
  type: 'light',
  mode: 'light',
  contrastThreshold: 3,
  background: {
    default: c.background,
    paper: c.paper,
    border: c.border,
    divider: c.border,
    disabled: ColorPalette.black04,
    thumb: c.thumb,
    menu: c.paper,
    sidebar: c.paper,
  },
  primary: {
    main: c.primary,
    dark: c.primary_dark,
    light: c.primary_light,
  },
  secondary: {
    main: c.primary,
    dark: c.primary_dark,
    light: c.primary_light,
  },
  iconography: {
    primary: c.icon,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: c.text_disabled,
  },
  typography: {
    primary: c.text_primary,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: ColorPalette.white01,
    link: c.primary,
  },
  inputs: {
    background: c.input_background,
    backgroundHover: c.input_background_hover,
    border: c.input_border,
    borderHover: c.input_border_hover,
    selectBorder: c.select_border,
  },
  table: {
    border: 'rgba(224,224,224,1)',
    hover: 'rgba(235, 242, 255, 0.35)',
    selected: 'rgba(235, 242, 255, 1)',
  },
  toggleButton: {
    unSelectedColor: ColorPalette.grey07,
  },
  text: {
    primary: c.text_primary,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: c.text_placeholder,
  },
  action: {
    hover: c.hover,
    selected: c.selected,
  },
  disabled: {
    background: 'rgba(97, 97, 97, 1)',
    text: '#ffffff',
  },
  alert: {
    main: 'rgba(0, 0, 0, 1)',
  },
};
theme.typography = typography;
theme.typography = {
  fontFamily: 'Montserrat',
  h1: {
    fontWeight: 600,
    fontSize: '2.5rem',
    letterSpacing: 0,
    lineHeight: '1.5',
  },
  h2: {
    fontWeight: 600,
    fontSize: '2rem',
    letterSpacing: 0,
    lineHeight: '1.5',
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.5rem',
    letterSpacing: 0,
    lineHeight: '1.5',
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.25rem',
    letterSpacing: 0,
    lineHeight: '1.5',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 0,
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.8125rem',
    letterSpacing: 0,
  },
  button_sm: {
    fontWeight: 600,
    fontSize: '0.75rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  button_med: {
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  button_lg: {
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  captiontext1: {
    fontWeight: 400,
    letterSpacing: 0,
    fontSize: '0.75rem',
  },
  captiontext2: {
    fontWeight: 400,
    letterSpacing: 0,
    fontSize: '0.625rem',
  },
};

export default theme;
