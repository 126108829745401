import { useContext, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { gql, useQuery } from '@apollo/client';
import { getUserById } from '../../queries/customQueries';
import globalStore from '../../store';
import { AuthContext } from '../../contexts';
import { Authorization } from '../../shared';
import useLocalStorage from '../useLocalStorage';
import { getUserType } from './utils';

const useInitializeDefaultStore = () => {
  const snap = useSnapshot(globalStore);
  const { user } = useContext(AuthContext);

  const [userPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar-user-mode',
  );

  useEffect(() => {
    // set loading status if not already fetched
    if (!snap.orgId) {
      globalStore.loading = true;
    }
    // refreshOrg();
  }, [snap.orgId]);

  const onError = (err) => {
    globalStore.loading = false;
    globalStore.error = err;
  };

  // get orgId and type
  useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    fetchPolicy: 'network-only',
    skip: !user || Boolean(snap.orgId),
    onCompleted: (_data) => {
      const {
        orgId,
        org,
        hmacId,
        email,
        theme,
        alias,
        name,
        permissions,
        participantType,
      } = _data?.getUserById?.items[0] || {};
      const isAffiliated = participantType === 'INTERNAL';
      const hasManagerialPermission = isAffiliated
        ? Authorization.hasManagerialPermissions(permissions)
        : false;
      // set user type based on user preferences and permissions
      globalStore.userType = getUserType({
        userPreferencesSidebarMode:
          userPreferencesSidebar?.[user?.username]?.mode,
        hasManagerialPermission,
      });
      globalStore.userHmacId = hmacId || '';
      globalStore.userEmail = email?.toLowerCase() || '';
      globalStore.userName = name || '';
      globalStore.userAlias = alias || '';
      if (!isAffiliated) {
        globalStore.orgId = orgId;
        globalStore.orgAlias = org?.alias || '';
        globalStore.orgName = org?.name || '';
        globalStore.orgPricingTier = org?.pricingTier || '';
        globalStore.orgMaxInvitees = org?.maxInvitees || '';
      }
      globalStore.theme = theme?.toLowerCase() || '';
      const isFdAmin =
        user?.signInUserSession?.accessToken.payload['cognito:groups'].includes(
          'fd-admin',
        );
      globalStore.permissions = [
        ...permissions,
        ...(isFdAmin ? ['FD_ADMIN'] : []),
      ];
      globalStore.isAffiliated = isAffiliated;
      globalStore.hasManagerialPermissions = isAffiliated
        ? permissions?.filter(
            (p) => !['PARTICIPATE', 'MANAGE_PROFILE'].includes(p), // managerial permissions includes all other permissions other than PARTICIPATE and MANAGE_PROFILE
          )?.length > 0
        : false;
    },
    onError,
  });
};

export default useInitializeDefaultStore;
