import { HttpLink } from '@apollo/client';

const apolloHttpLink = () => {
  const requestLink = new HttpLink({
    uri: '/graphql/',
    credentials: 'include',
  });
  return requestLink;
};

export default apolloHttpLink;
