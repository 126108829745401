import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, useTheme } from '@material-ui/core';
import FdTooltip from '../FdTooltip';
import FdIcons from '../FdIcons/FdIcons';

const FdIconWithTooltip = ({ title, Icon }) => {
  const theme = useTheme();
  const { InfoOutlined } = FdIcons;
  return (
    <FdTooltip title={title}>
      <IconButton
        size="small"
        style={{ marginLeft: '5px', color: theme.palette.typography.primary }}
      >
        {Icon ? <Icon /> : <InfoOutlined />}
      </IconButton>
    </FdTooltip>
  );
};

FdIconWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  Icon: PropTypes.node,
};

FdIconWithTooltip.defaultProps = {
  Icon: undefined,
};

export default FdIconWithTooltip;
