import axios from 'axios';
import { getAccessToken } from '../apolloLinks/tokenTempStorage';

const sendEvent = (eventType, details) => {
  const properties = {
    ...details,
    metadata: {
      search: window.location.search,
      url: window.location.href,
      path: window.location.pathname,
      userAgent: navigator.userAgent,
    },
  };
  axios.post('/api/v1/usage/event',
    {
      eventType,
      properties,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${getAccessToken()}`,
      },
    });
};

export default sendEvent;
