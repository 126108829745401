import {
  proxyWithComputed,
  // devtools,
} from 'valtio/utils';
import _ from 'lodash';
import { getRoles, isAdminOrManager } from './utils';
import { ReactQueryClient } from '../clients';

const initialValues = {
  user: undefined,
  userType: undefined,
  userEmail: undefined,
  userName: undefined,
  userHmacId: undefined,
  userAlias: undefined,
  orgProducts: undefined,
  loading: false,
  error: undefined,
  orgImage: undefined,
  orgId: undefined,
  orgAlias: undefined,
  orgName: undefined,
  orgPricingTier: undefined,
  orgMaxInvitees: undefined,
  isAAFCOrg: false,
  theme: undefined,
  logoS3ImageUrl: undefined,
  hasManagerialPermissions: false,
  isAffiliated: false,
  permissions: undefined,
};

// store definition
const globalStore = proxyWithComputed(
  {
    ...initialValues,
  },
  {
    userId: (snap) => snap.user?.username || '',
    name: (snap) => snap.user?.attributes?.name || '',
    email: (snap) => snap.user?.attributes?.email || '',
    roles: (snap) => getRoles(snap.user) || [],
    isAdminOrManager: (snap) => isAdminOrManager(snap.roles),
  },
);

export const resetGlobalStore = () => {
  //  deep clone to allows Valtio to update correctly when the value of a key is an object
  const resetObj = _.cloneDeep(initialValues) || {};
  Object.keys(resetObj).forEach((key) => {
    globalStore[key] = resetObj[key];
  });
  // remove all cached queries on logout
  ReactQueryClient.removeQueries();
};

// NOTE: uncomment to use redux devtools
// devtools(globalStore);

export default globalStore;
