import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FdLoadingSpinner from './FdLoadingSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    '&.MuiCardContent-root:last-child': {
      paddingBottom: 16,
    },
  },
  item: {
    display: 'inline-block',
    alignSelf: 'center',
    paddingLeft: theme.spacing(1),
  },
}));

export default function FdLoadingCard(props) {
  const { message } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent className={classes.root}>
          <FdLoadingSpinner />
          <Typography className={classes.item}>
            {message ? `  ${message.toString()}` : '  Loading...'}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

FdLoadingCard.propTypes = {
  message: PropTypes.string,
};

FdLoadingCard.defaultProps = {
  message: null,
};
