export const fdProColors = {
  electricBlue: {
    50: '#EBF2FF',
    100: '#DBE6FF',
    200: '#BED1FF',
    300: '#97B1FF',
    400: '#6E84FF',
    500: '#4C5AFF',
    600: '#3333FF',
    700: '#2420E2',
    800: '#1D1DB6',
    900: '#20228F',
    950: '#131353',
    990: '#09092E',
  },
  alert: {
    success: '#35C3A1',
    successLight: '#CAF7EC',
    successDark: '#2E987F',
    warning: '#F4A65E',
    warningLight: '#FFF3E0',
    warningDark: '#EF6C00',
    error: '#E57387',
    errorLight: '#FFDBE6',
    errorDark: '#C62828',
    info: '#86A8FF',
    infoLight: '#DBE6FF',
    infoDark: '#1565C0',
    default: '#BDBDBD',
    defaultLight: '#EFEFEF',
    defaultDark: '#8D8D8D',
  },
  event: {
    archive: '#E0E0E0',
    competitions: '#97B1FF',
    courses: '#7BE7DF',
    assessments: '#E6A4D7',
    trainings: '#B3E5FC',
  },
};
