/* FdChips */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box, Chip } from '@material-ui/core';
import FdTypography from '../FdTypography';
import { getTextColorByBackgroundColor } from '../../shared';

const FdChips = ({ data = [], numberOfChipsDisplayed, caption }) => {
  const chipsDisplayed = numberOfChipsDisplayed || data?.length;
  const chips = [...data].slice(0, chipsDisplayed);
  const moreValue = [...data].slice(chipsDisplayed).length || 0;

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {chips?.map((c) => (
        <Box mr={0.5} key={shortid.generate()}>
          <Chip
            size="small"
            label={c.label}
            style={{
              backgroundColor: c.color,
              color: getTextColorByBackgroundColor(c.color),
            }}
          />
        </Box>
      ))}
      {moreValue > 0 && (
        <FdTypography variant="caption">{`+${moreValue} ${caption}`}</FdTypography>
      )}
    </Box>
  );
};

FdChips.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  numberOfChipsDisplayed: PropTypes.number,
  caption: PropTypes.string,
};

FdChips.defaultProps = {
  numberOfChipsDisplayed: undefined,
  caption: '',
};

export default FdChips;
