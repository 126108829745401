import React from 'react';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';

function FdCountdown({ systemDate, endDateTime, onFinish, ...props }) {
  const systemDateTime = systemDate?.getTime() || Date.now();
  const remainingTime = new Date(endDateTime).getTime() - systemDateTime;

  return (
    <Countdown
      {...props}
      date={systemDateTime + remainingTime}
      now={() => systemDateTime}
      onComplete={onFinish}
      daysInHours
    />
  );
}

FdCountdown.propTypes = {
  endDateTime: PropTypes.string.isRequired,
  onFinish: PropTypes.func,
  systemDate: PropTypes.string,
};

FdCountdown.defaultProps = {
  onFinish: () => {},
  systemDate: undefined,
};

export default FdCountdown;
