import { useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import AuthContext from '../contexts/AuthContext/auth';
import { getUserById } from '../queries/customQueries';

const useOrgId = () => {
  const { user } = useContext(AuthContext);
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
  });

  return {
    user,
    orgId: userData?.getUserById?.items[0].orgId,
    userData: userData?.getUserById?.items[0],
    userLoading,
  };
};

export default useOrgId;
