const testConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id:
    'ap-southeast-2:ed89a582-1929-4d84-92cb-38cb2caaf899',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_sCDXxAwI2',
  aws_user_pools_web_client_id: '6b8t38vo39mo4nsg3gi8bj1s7g',
  oauth: {},
  aws_cognito_login_mechanisms: ['EMAIL'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_appsync_graphqlEndpoint:
    'https://36utgdbphzfzjlohxjybmqooga.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: 'task-file-storage232-production',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
};

// const stagingConfig = {
//   aws_project_region: 'ap-southeast-2',
//   aws_cognito_identity_pool_id:
//     'ap-southeast-2:b73e761d-2f3d-4cde-be36-e368245e8ddf',
//   aws_cognito_region: 'ap-southeast-2',
//   aws_user_pools_id: 'ap-southeast-2_vu9o1UaeA',
//   aws_user_pools_web_client_id: '1eo43kh0epkvti7be25oitco31',
//   oauth: {},
//   aws_cognito_username_attributes: [],
//   aws_cognito_social_providers: [],
//   aws_cognito_signup_attributes: ['EMAIL'],
//   aws_cognito_mfa_configuration: 'OPTIONAL',
//   aws_cognito_mfa_types: ['SMS'],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: [],
//   },
//   aws_cognito_verification_mechanisms: ['EMAIL'],
//   aws_appsync_graphqlEndpoint:
//     'https://pqlrfojl65aozo5t75idygnkoa.appsync-api.ap-southeast-2.amazonaws.com/graphql',
//   aws_appsync_region: 'ap-southeast-2',
//   aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//   aws_cloud_logic_custom: [
//     {
//       name: 'labsapi',
//       endpoint:
//         'https://vifsnob1na.execute-api.ap-southeast-2.amazonaws.com/stagetwo',
//       region: 'ap-southeast-2',
//     },
//   ],
//   aws_user_files_s3_bucket: 'task-file-storage163244-staging',
//   aws_user_files_s3_bucket_region: 'ap-southeast-2',
// };

const stagingConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id:
    'ap-southeast-2:4101fbc8-16ef-4089-85d3-deb0bd4f373a',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_ffcKQzuBX',
  aws_user_pools_web_client_id: '78q62akmbmue8of6duaed14n1c',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://tvhumdmturfspaomao2iw6db4q.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'labsapi',
      endpoint:
        'https://vifsnob1na.execute-api.ap-southeast-2.amazonaws.com/stagetwo',
      region: 'ap-southeast-2',
    },
  ],
  aws_user_files_s3_bucket: 'task-file-storage232-staging',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
};


const externalConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id:
    'ap-southeast-2:75099ce5-0727-47ae-9bee-fdf093e680cb',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_HYSOn6Sah',
  aws_user_pools_web_client_id: '29l22l7a5gpfmseck2kruhpkn1',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://tlx7gmc56zfv5ivhivkbbs6d6u.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'labsapi',
      endpoint:
        'https://vifsnob1na.execute-api.ap-southeast-2.amazonaws.com/stagetwo',
      region: 'ap-southeast-2',
    },
  ],
  aws_user_files_s3_bucket: 'task-file-storage232-testing',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
};


const prodConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id:
    'ap-southeast-2:0a1d7511-7255-46d6-a26f-d6b6c9ba1bbd',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_42VvVB9OE',
  aws_user_pools_web_client_id: '15gj4e3h2ho7frhnjdddldcbkk',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://36utgdbphzfzjlohxjybmqooga.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: 'task-file-storage232-production',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
};

const yubiConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_appsync_graphqlEndpoint:
    'https://it5dlpikbrakpm7wjzds4yivqy.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'keepalive',
      endpoint:
        'https://vvznmmbqy8.execute-api.ap-southeast-2.amazonaws.com/yubi',
      region: 'ap-southeast-2',
    },
    {
      name: 'labsapi',
      endpoint:
        'https://ddao3dvbfj.execute-api.ap-southeast-2.amazonaws.com/yubi',
      region: 'ap-southeast-2',
    },
  ],
  aws_cognito_identity_pool_id:
    'ap-southeast-2:de8d1183-750d-4029-a0e7-7287841ffaa3',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_VM3XqHoMH',
  aws_user_pools_web_client_id: '5298u7p6f68hpncrv5cgd28t0c',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'task-file-storage105205-yubi',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  aws_cognito_login_mechanisms: ['EMAIL'],
};

// eslint-disable-next-line no-nested-ternary
const config =
  document.location.host.includes('localhost') ||
  document.location.host.includes('test.')
    ? testConfig
    : document.location.host.includes('staging.')
    ? stagingConfig
    : document.location.host.includes('external.')
    ? externalConfig
    : prodConfig;

export default config;
