import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputLabel, Typography } from '@material-ui/core';
import {
  FormatBold,
  FormatItalic,
  StrikethroughS,
  Link,
  FormatSize,
  FormatQuote,
  Code,
  Image,
  FormatListBulleted,
  FormatListNumbered,
  PlaylistAddCheck,
} from '@material-ui/icons';
import ReactMde, { getDefaultToolbarCommands } from 'react-mde';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { SvgIcon } from 'react-mde/lib/js/icons';
import useDimensions from 'react-use-dimensions';
import useStyles from './styles';
import 'react-mde/lib/styles/css/react-mde-all.css';
import FdTooltip from '../FdTooltip';

const FdMarkdownEditor = ({
  id,
  markdown,
  setMarkdown,
  toolTips,
  label,
  disabled,
  required,
  error,
  errorText,
  helpText,
  minEditorHeight,
  paste,
  disablePreview,
  customCommands,
  customToolbarCommands,
  ...props
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState('write');
  const [ref, { width }] = useDimensions();
  const iconList = {
    header: {
      Icon: FormatSize,
      toolTip: 'Heading',
    },
    bold: {
      Icon: FormatBold,
      toolTip: 'Bold',
    },
    italic: {
      Icon: FormatItalic,
      toolTip: 'Italic',
    },
    strikethrough: {
      Icon: StrikethroughS,
      toolTip: 'Strikethrough',
    },
    link: {
      Icon: Link,
      toolTip: 'Link',
      seperator: true,
    },
    quote: {
      Icon: FormatQuote,
      toolTip: 'Quote',
    },
    code: {
      Icon: Code,
      toolTip: 'Code',
    },
    image: {
      Icon: Image,
      toolTip: 'Image',
    },
    'unordered-list': {
      Icon: FormatListBulleted,
      toolTip: 'Un-ordered List',
    },
    'ordered-list': {
      Icon: FormatListNumbered,
      toolTip: 'Ordered List',
    },
    'checked-list': {
      Icon: PlaylistAddCheck,
      toolTip: 'Checked List',
    },
  };
  const getIcon = (commandName) => {
    const { Icon, toolTip } = iconList[commandName] || {};
    return Icon ? (
      <FdTooltip title={toolTips[commandName] || toolTip}>
        <Icon />
      </FdTooltip>
    ) : (
      <SvgIcon icon={commandName} />
    );
  };
  const mdHelpText = error && errorText ? errorText : helpText;

  return (
    <Box
      {...props}
      id={id}
      className={`${classes.reactMdeRoot} ${
        width < 642 && classes.toolbarDense
      } ${error && classes.reactMdeError}`}
      style={{ fontFamily: 'Montserrat, sans-serif' }}
      ref={ref}
    >
      {id && label && (
        <InputLabel
          htmlFor={id}
          data-cy={`label-for-${id}`}
          classes={{
            root: classes.labelRoot,
          }}
          disabled={disabled}
        >
          {label}
          {!required && (
            <span
              className={classes.optionalLabel}
              style={{ opacity: disabled && '30%' }}
            >
              &nbsp;optional
            </span>
          )}
        </InputLabel>
      )}
      <ReactMde
        id={id}
        value={markdown}
        disablePreview={disablePreview}
        onChange={setMarkdown}
        getIcon={getIcon}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        commands={customCommands}
        toolbarCommands={[
          ...getDefaultToolbarCommands(),
          ...customToolbarCommands,
        ]}
        generateMarkdownPreview={(_markdown) =>
          Promise.resolve(
            <ReactMarkdown
              linkTarget="_blank"
              // eslint-disable-next-line react/no-children-prop
              children={_markdown}
              remarkPlugins={[gfm]}
            />,
          )
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
        minEditorHeight={minEditorHeight}
        minPreviewHeight={minEditorHeight - 10}
        disabled={disabled}
        required={required}
        paste={paste}
      />
      {mdHelpText && (
        <Box mt={0.5}>
          <Typography
            variant="caption"
            className={error ? classes.errorText : classes.helpText}
          >
            {mdHelpText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

FdMarkdownEditor.propTypes = {
  /** Id for markdown editor */
  id: PropTypes.string.isRequired,
  /** Label for markdown editor */
  label: PropTypes.string,
  /** markdown value */
  markdown: PropTypes.string.isRequired,
  /** markdown value set function - setState function */
  setMarkdown: PropTypes.func.isRequired,
  /**  custom tooltips for each button */
  toolTips: PropTypes.objectOf(
    PropTypes.oneOf([
      'header',
      'bold',
      'italic',
      'strikethrough',
      'link',
      'quote',
      'code',
      'image',
      'unordered-list',
      'ordered-list',
      'checked-list',
    ]).isRequired,
    PropTypes.string.isRequired,
  ),
  /** Required or not */
  required: PropTypes.bool,
  /** Disabled or not */
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helpText: PropTypes.string,
  minEditorHeight: PropTypes.number,
  paste: PropTypes.shape({
    saveImage: PropTypes.func.isRequired,
  }),
  disablePreview: PropTypes.bool,
  customCommands: PropTypes.objectOf(PropTypes.func),
  customToolbarCommands: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

FdMarkdownEditor.defaultProps = {
  toolTips: {},
  label: '',
  required: false,
  disabled: false,
  error: false,
  errorText: '',
  helpText: '',
  minEditorHeight: 218,
  paste: undefined,
  disablePreview: false,
  customCommands: null,
  customToolbarCommands: [],
};

export default React.memo(FdMarkdownEditor);
