import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';

function GenericRoute({ routes, redirectPath }) {
  return (
    <Switch>
      {routes.map((prop) => (prop.component ? (
        <Route
          path={prop.path}
          component={prop.component}
          key={prop.name}
          exact={prop.exact}
        />
      ) : (
        <Route
          path={prop.path}
          render={() => prop.element}
          key={prop.name}
          exact={prop.exact}
        />
      )))}
      {redirectPath && (
        <Route>
          <Redirect to={redirectPath} />
        </Route>
      )}
    </Switch>
  );
}

GenericRoute.defaultProps = {
  redirectPath: '',
};

GenericRoute.propTypes = {
  redirectPath: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.func,
      element: PropTypes.element,
      exact: PropTypes.bool,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default GenericRoute;
