// set of utilities to define a color based on input string
const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

// get an avatar color based on the username
export const getAvatarColor = (
  userName = '-',
  saturationRange = [40, 60],
  lightnessRange = [40, 60],
) => generateColorHsl(userName, saturationRange, lightnessRange);

// get avatar name from the given username
export const getAvatarName = (userName) => {
  if (!userName) {
    return '-';
  }
  const words = userName.split(' ');
  let firstLetters = '';
  if (words.length === 1) {
    // if only one word, use the first letter of the word
    firstLetters = words[0].charAt(0);
  } else {
    // if two or more words, get first letter of each of the first two words
    firstLetters = words
      .slice(0, 2)
      .map((word) => word.charAt(0))
      .join('');
  }
  return firstLetters.toUpperCase();
};
