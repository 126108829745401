import { SPECIALTY_SORT } from '../constants';
import { getArrayByLength } from './objectUtils';

export const cortexSort = (a, b) =>
  SPECIALTY_SORT[a?.specialtyName]?.order -
  SPECIALTY_SORT[b?.specialtyName]?.order;

export const getCortexLevels = (idx) => {
  const difficultyLevels = getArrayByLength(5, (__, i) => i + 1);
  const flip = (idx + 1) % 2 === 1;
  const levels = flip ? [...difficultyLevels].reverse() : difficultyLevels;
  return { levels, flip };
};
