import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  linkText: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.typography.link,
  },
  linkButton: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.typography.link,
  },
  selectedText: {
    color: theme.palette.typography.primary,
    fontSize: '16px',
    lineHeight: 1.6,
  },
  separator: {
    color: theme.palette.typography.primary,
    fontSize: '16px',
  },
}));
